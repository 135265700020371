import { Avatar, Box, Button, Link, MenuItem, Typography } from "@mui/material";
import React from "react";
import { StandadCard } from "../../../../../../../components/Cards";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { useDispatchWrapper, useRecordCounts } from "../../../../../../../hooks";
import { ILegal } from "../../../../../../../redux/legals/legals.types";

import { RoleBasedCSVExportPlugin } from "../../../../../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { DateFilterDropdown } from "../../../../../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../../../../../../components/Table/hooks/useDateFilter";
import {
  RefreshPlugin,
  SearchPlugin,
} from "../../../../../../../components/Table/plugins";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
// import { AddEDocsDialog } from "./AddEDocsDialog/AddEDocsDialog";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { PageContainer } from "../../../../../../../components/container/PageContainer";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { CustomLink } from "../../../../../../../components/CustomLink/CustomLink";
import { ICSIORaw } from "../../../../../../../redux/csio/csio.types";
import { CSIOMapRightPanel } from "../../../../../../csio/CSIOMapRightPanel";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {
  clearAutomobileCSIOApplicationPolicyReqState,
  fetchCSIOAutomobilePolicyReqListAsync,
} from "../../../../../../../redux/automobileCSIOPolicyChangeReq/automobileCSIOPolicyChangeReqActions";
import { fetchCSIOPolicyChangeListAsync } from "../../../../../../../redux/automobileCSIOApplication/automobileCSIOApplicationActions";
import { PageTitleBar } from "../../../../../../../components/PageTitleBar/PageTitleBar";
import { AddOutlined } from "@mui/icons-material";
import { fetchCSIOHabitationalPolicyChangeListAsync } from "../../../../../../../redux/habitationalPolicy/habitationalCsioApplication/habitationalCsioApplicationActions";

export const HabitationalCSIOPolicyEndorsementList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) => storeState.habitational.csioapplication
  );
  const dispatch = useDispatchWrapper();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [status, setStatus] = React.useState<ILegal["status"] | "-1">("-1");
  const [searchType, setSearchType] = React.useState<string[]>([
    "message_guid",
  ]);
  const [search, setSearch] = React.useState<string>("");
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "allTimes" });

  const [openCSIO, setOpenCSIO] = React.useState<ICSIORaw | null>(null);
  const [map, setMap] = React.useState<string | "-1">("-1");

  const navigate = useNavigate();

  const csioTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    isDataLoading: loading === LoadState.InProgress,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          // {
          //   key: "0",
          //   onRender: () => (
          //     <CustomSelect
          //       sx={{ minWidth: "120px" }}
          //       value={map}
          //       onChange={(e) => setMap(e.target.value as string)}
          //     >
          //       <MenuItem value={"-1"}>Both</MenuItem>;
          //       <MenuItem value={"1"}>Matched</MenuItem>
          //       <MenuItem value={"0"}>Unmatched</MenuItem>
          //     </CustomSelect>
          //   ),
          // },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                items={items}
                moduleId={MODULE_IDS.LEGAL}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {},
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Message Guid", value: "message_guid" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#000",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(`/manage-csio-tab/${row.message_guid}`);
              }}
            />
          );
        },
      },
      {
        key: "csio",
        headerName: "",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 20,
        enableSorting: false,

        onRowCellRender: (values, row) => {
          return (
            <Button
              variant="text"
              onClick={() =>
                navigate(
                  `/habitational-policy/${customerId}/habitational-application-csio-tab/${customerpolicyId}/policy-change/${row.message_guid}`
                )
              }
            >
              View XML & Map
            </Button>
          );
        },
      },
      {
        key: "message_guid",
        headerName: "Message ID",
        fieldName: "message_guid",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <CustomLink
              to={"/manage-csio-tab/" + row.message_guid}
              label={row.message_guid}
            />
          );
        },
      },
      {
        key: "MessageType",
        headerName: "Message Type",
        fieldName: "message_type",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 15,
      },
      {
        key: "FromEmailAddress",
        headerName: "From Email Address",
        fieldName: "from_email_address",
        enableSorting: false,
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
      },
      {
        key: "is_map",
        headerName: "Map",
        fieldName: "is_map",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 15,
        onRowCellRender: (value, row) => {
          return row.is_map === 1 ? (
            <CheckCircleRoundedIcon fontSize="medium" color="success" />
          ) : (
            <CancelIcon fontSize="medium" color="error" />
          );
        },
      },
      {
        key: "DateTime",
        headerName: "Date and Time",
        fieldName: "create_ts",
        renderType: RenderType.DATE_TIME,
        exportCellWidth: 20,
        enableSorting: false,
      },
    ],
    items: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    // tableTabProps: {
    //   selectedTab: status,
    //   tabs: tableTabs,
    //   onTabChange: (newSelectedTab) => {
    //     setStatus(newSelectedTab);
    //   },
    // },
  };

  const fetchList = () => {
    dispatch(
      fetchCSIOHabitationalPolicyChangeListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        search,
        searchType,
        date,
        map,
        customerpolicyId
      )
    );
  };

  // const { list: recordsCounts, loading: recordCountsLoading } =
  //   useRecordCounts();
  // React.useEffect(() => {
  //   dispatch(
  //     fetchRecordCountsAsync(
  //       "latest_csio_retrieve_messages",
  //       "status",
  //       date.fromDate,
  //       date.toDate
  //     )
  //   );
  //   return () => {
  //     dispatch(clearRecordCounts());
  //   };
  // }, [date.fromDate, date.toDate]);

  // React.useEffect(() => {
  //   const tabsData = createTabsWithRecordcounts(
  //     [
  //       { label: "Active", value: "ACTIVE", variant: "success" },
  //       { label: "Inactive", value: "INACTIVE", variant: "error" },
  //     ],
  //     recordsCounts
  //   );
  //   setTableTabs(tabsData);
  // }, [recordsCounts]);

  React.useEffect(() => {
    fetchList();
  }, [status, date, tablePagination, search, map]);

  const handleFetch = () => {
    navigate("/csio-progress");
  };

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobileCSIOApplicationPolicyReqState());
    };
  }, []);

  return (
    <>
      <PageContainer title="View Csio" description="this is innerpage">
        <DataTable {...csioTableProps} />
      </PageContainer>
      {openCSIO && (
        <CSIOMapRightPanel
          open={true}
          messageGUID={openCSIO.message_guid}
          onClose={() => setOpenCSIO(null)}
        />
      )}
    </>
  );
};
