import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import React from "react";
import { BeforeCreateTypes } from "../../../../tasks/BeforeCreate/BeforeCreate.types";
import { useDateFilter } from "../../../../../components/Table/hooks/useDateFilter";
import { useDispatchWrapper } from "../../../../../hooks";
import { downloadPoliciesCancellationListPDFAsync, fetchTasksListAsync, previewPoliciesCancellationListPDFAsync } from "../../../../../redux/tasks/tasksActions";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../components/Table/interfaces/IDataTableProps";
import { LoadState } from "../../../../../constants/enums";
import { Avatar, Button } from "@mui/material";
import { LeadOppStatus } from "../../../../../components/LeadOppStatus/LeadOppStatus";
import { ITask } from "../../../../../redux/tasks/task.types";
import { TaskAssignedTo } from "../../../../tasks/BeforeCreate/TaskAssignedTo";
import { StandardTableActions } from "../../../../../components/StandardTableActions/StandardTableActions";
import { StandadCard } from "../../../../../components/Cards";
import { DataTable } from "../../../../../components/Table/DataTable";
import { AddOutlined } from "@mui/icons-material";
import { CancellationDialog } from "./CancellationDialog";

export const PolicyCancellationList: React.FC<{
  customerId: number;
  customerPolicyId: number;
  onSaveEditClick: (taskCode: string) => void;
}> = (props) => {
  const { customerId, customerPolicyId, onSaveEditClick } = props;

  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) => storeState.tasks.allTasks
  );

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: BeforeCreateTypes[];
  }>({
    isAllSelected: false,
    leads: [],
  });
  const [status, setStatus] = React.useState<
    BeforeCreateTypes["status"] | "-1"
  >("-1");

  const [searchType, setSearchType] = React.useState<string[]>(["task_name"]);
  const [search, setSearch] = React.useState<string>("");
  const [downloading, setDownloading] = React.useState<boolean>(false);


  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "lastWeek" });

  const dispatch = useDispatchWrapper();

  const [open, setOpen] = React.useState<boolean>(false);
  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  const changeNextStep = (taskCode: string) => {
    onSaveEditClick(taskCode);
  };

  const fetchList = async () => {
    dispatch(
      fetchTasksListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        search,
        searchType,
        customerPolicyId,
        "Cancellation/Reinstatement",
        undefined
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, date, status, search, searchType]);

  const opportunitesTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        plugins: {},
        customPlugins: [],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "task_status",
        headerName: "Status",
        fieldName: "task_status",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ITask) => {
          return <LeadOppStatus status={row.status} />;
        },
      },

      {
        key: "task_type",
        headerName: "Transaction",
        fieldName: "task_type",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "task_sub_type",
        headerName: "Sub-Type",
        fieldName: "task_sub_type",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },
      // {
      //   key: "customer_name",
      //   headerName: "Customer Name",
      //   fieldName: "customer_name",
      //   exportCellWidth: 25,
      //   renderType: RenderType.TEXT_DARK,
      // },
      // {
      //   key: "policy_number",
      //   headerName: "Policy Number",
      //   fieldName: "policy_number",
      //   exportCellWidth: 25,
      //   renderType: RenderType.TEXT_DARK,
      // },
      {
        key: "task_code",
        headerName: "Task Code",
        fieldName: "task_code",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_SUCCESS,
        // onRowCellRender: (value, row: ITask) => {
        //   return <LeadOppStatus status={row.task_status} />;
        // },
      },

      {
        key: "task_created_date",
        headerName: "Date Created",
        fieldName: "task_created_date",
        renderType: RenderType.DATE,
      },
      {
        key: "task_due_date",
        headerName: "Due Date",
        fieldName: "task_due_date",
        renderType: RenderType.DATE,
      },
      {
        key: "insert_ts",
        headerName: "Date Modified",
        fieldName: "insert_ts",
        renderType: RenderType.DATE,
      },
      {
        key: "assigned_to",
        headerName: "Assigned To",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return <TaskAssignedTo task={row} onComplete={fetchList} />;
        },
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              // onViewClick={() => setOpenView(row as ITask)}
              onEditClick={() => {
                onSaveEditClick(row.task_code);
              }}
              onDownloadPreview={() => {
                dispatch(previewPoliciesCancellationListPDFAsync(row.task_code, () => {}))
              }}
              onDownLoadClick={() => {
                  setDownloading(true);
                  dispatch(
                    downloadPoliciesCancellationListPDFAsync(
                      row.task_code,
                      () => {
                        setDownloading(false);
                      }
                    )
                  );
                }
              }
            />
          );
        },
      },
    ],
    items: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: BeforeCreateTypes[]) => {
      setSelectedOppr({ isAllSelected: isAllSelected, leads: rows });
    },
  };

  return (
    <StandadCard
      rightHeading={
        <Button
          variant="contained"
          onClick={handleClose}
          startIcon={<AddOutlined />}
        >
          Create
        </Button>
      }
    >
      <DataTable {...opportunitesTableProps} />

      {open && customerId && customerPolicyId && (
        <CancellationDialog
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          open={open}
          onClose={handleClose}
          changeNextStep={changeNextStep}
        />
      )}
    </StandadCard>
  );
};
