/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import { TabPanel } from "../../../../../../components/Tabs/TabPanel";

import { TabsList } from "../../../../../../components/Tabs/TabsList";
import {
  AccountCircle,
  AdminPanelSettings,
  Receipt,
  StickyNote2,
} from "@mui/icons-material";
import { clearAutomobileCSIOApplicationPolicyReqState, fetchAutomobileCSIOApplicationPolicyReqAsync } from "../../../../../../redux/automobileCSIOPolicyChangeReq/automobileCSIOPolicyChangeReqActions";
import { AutomobileCSIOPolicyChangeReqStep1 } from "./AutomobileCSIOPolicyChangeReqStep1";
import { AutomobileCSIOPolicyChanngeReqStep2 } from "./AutomobileCSIOPolicyChangeReqStep2";
import { AutomobileCSIOPolicyChangeReqStep3 } from "./AutomobileCSIOPolicyChangeReqStep3";
export const AutomobilePolicyChangeReqForm: React.FC = () => {
  const { customerId, customerPolicyId, messageGUID } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    messageGUID?: string;
  };

  const { policyChangeData, loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.csioPolicyChangeReq
  );

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: policyChangeData,
    validate: (values) => {},
    onSubmit: (values) => {
      
    },
  });
  const handleStepChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const onTabChange = (value: number) => {
    window.scrollTo(0, 400);
    setTab(value);
  };

  // React.useEffect(() => {
  //   if (messageGUID) {
  //     dispatch(fetchAutomobileCSIOApplicationPolicyReqAsync(messageGUID));
  //   }
  // }, [messageGUID]);

  React.useEffect(() => {
    setValues(policyChangeData);
  }, [policyChangeData]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobileCSIOApplicationPolicyReqState());
    };
  }, []);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <StandadCard sx={{ mt: 2 }} heading="Automobile Policy Change Req Form">
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={tab}
          tabs={[
            { label: "Step 1", icon: <AccountCircle /> },
            {
              label: "Step 2",
              icon: <AdminPanelSettings />,
              //   disabled: !savedCustomerPolicyId,
            },
            {
              label: "Step 3",
              icon: <StickyNote2 />,
              //   disabled: !savedCustomerPolicyId,
            },
          ]}
          onChange={handleStepChange}
        />
        <form onSubmit={handleSubmit}>
          <TabPanel index={0} value={tab}>
            <AutomobileCSIOPolicyChangeReqStep1
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setValues={setValues}
              changeTab={onTabChange}
            />
          </TabPanel>
          <TabPanel index={1} value={tab}>
            <AutomobileCSIOPolicyChanngeReqStep2
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setValues={setValues}
              changeTab={onTabChange}
            />
          </TabPanel>

          <TabPanel index={2} value={tab}>
            <AutomobileCSIOPolicyChangeReqStep3
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setValues={setValues}
              changeTab={onTabChange}
            />
          </TabPanel>

        </form>
      </StandadCard>
    </PageLoader>
  );
};
