import React from "react";
import { ICommAutomobileApplication } from "../../../../../../redux/commAutoPolicy/commAutoApplication/commAutoApplication.types";
import {
  Button,
  Checkbox,
  Grid,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import produce from "immer";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { Stack } from "@mui/system";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { MODULE_IDS } from "../../../../../../constants/enums";
import {
  IAutomobileCSIOAccidentsSuspension,
  IAutomobileCSIOConviction,
  IAutomobileCSIOOperatorOne,
  IAutomobileCSIOOperatorTwo,
  IAutomobileCSIOPolicyState,
} from "../../../../../../redux/automobileCSIOPolicyChangeReq/automobileCSIOPolicyChangeReq.types";
import { StandadCard } from "../../../../../../components/Cards";
import { ILocationResponsePayload, LocationAutoComplete } from "../../../../../../components/LocationAutoComplete/LocationAutoComplete";

export const AutomobileCSIOPolicyChangeReqStep3: React.FC<{
  values: IAutomobileCSIOPolicyState;
  handleChange: any;
  setValues: any;
  setFieldValue: any;
  changeTab: (value: number) => void;
}> = (props) => {
  const { values, setValues, handleChange, setFieldValue } = props;

  // const updateDriverInformationOne = (
  //   key: string,
  //   rowIndex: number,
  //   value: any
  // ) => {
  //   const newValues = produce(
  //     values.driver_information.section_one,
  //     (draftValues) => {
  //       //@ts-ignore
  //       draftValues[rowIndex][key] = value;
  //     }
  //   );
  //   setFieldValue("driver_information.section_one", newValues);
  // };

  // const handleDriverInformationOne =
  //   (key: string, rowIndex: number) =>
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     updateDriverInformationOne(key, rowIndex, e.target.value);
  //   };

  // const handleSelectDriverInformationOne =
  //   (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
  //     updateDriverInformationOne(key, rowIndex, e.target.value);
  //   };

  const updateOperatorOne = (key: string, rowIndex: number, value: any) => {
    const newValues = produce(values.described_operators_one, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("described_operators_one", newValues);
  };

  const handleOperatorOne =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateOperatorOne(key, rowIndex, e.target.value);
    };

  const handleSelectOperatorOne =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateOperatorOne(key, rowIndex, e.target.value);
    };

  const describeOperatorOne: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 100,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "name_of_driver",
        headerName: "Name (As Shown on Driver's Licence) ",
        fieldName: "name_of_driver",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOOperatorOne) => {
          return (
            <CustomTextField
              fullWidth
              value={row.name_of_driver}
              onChange={handleOperatorOne(
                "name_of_driver",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "driver_licence_number",
        headerName: "Driver License Number",
        fieldName: "driver_licence_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOOperatorOne) => {
          return (
            <CustomTextField
              fullWidth
              value={row.driver_licence_number}
              onChange={handleOperatorOne(
                "driver_licence_number",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "sex",
        headerName: "Sex",
        fieldName: "sex",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOOperatorOne) => {
          return (
            <CustomTextField
              fullWidth
              value={row.sex}
              onChange={handleOperatorOne("sex", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "marital_status",
        headerName: "Martial Status",
        fieldName: "marital_status",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOOperatorOne) => {
          return (
            <CustomTextField
              fullWidth
              value={row.marital_status}
              onChange={handleOperatorOne(
                "marital_status",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.described_operators_one,
  };
  const updateOperatorTwo = (key: string, rowIndex: number, value: any) => {
    const newValues = produce(values.described_operators_two, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("described_operators_two", newValues);
  };

  const handleOperatorTwo =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateOperatorTwo(key, rowIndex, e.target.value);
    };

  const handleSelectOperatorTwo =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateOperatorTwo(key, rowIndex, e.target.value);
    };

  const describeOperatorTwo: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 100,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "assigned_vin_number",
        headerName: "Assigned Vehicle V.I.N (Serial Number) ",
        fieldName: "assigned_vin_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOOperatorTwo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.assigned_vin_number}
              onChange={handleOperatorTwo(
                "assigned_vin_number",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "use",
        headerName: "Use % ",
        fieldName: "use",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOOperatorTwo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.use}
              onChange={handleOperatorTwo("use", value.column.rowIndex)}
            />
          );
        },
      },

      {
        key: "date_of_birth",
        headerName: "Date of Birth",
        fieldName: "date_of_birth",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOOperatorTwo) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date_of_birth}
              onChange={(newValue) => {
                updateOperatorTwo(
                  "date_of_birth",
                  value.column.rowIndex,
                  newValue
                );
              }}
            />
          );
        },
      },
      {
        key: "license_class_one",
        headerName: "Licence Class",
        fieldName: "marital_status",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOOperatorTwo) => {
          return (
            <Stack direction={"row"} spacing={1}>
              <CustomTextField
                fullWidth
                value={row.license_class_one}
                onChange={handleOperatorTwo(
                  "license_class_one",
                  value.column.rowIndex
                )}
              />
              <CustomTextField
                fullWidth
                value={row.license_class_two}
                onChange={handleOperatorTwo(
                  "license_class_two",
                  value.column.rowIndex
                )}
              />
              <CustomTextField
                fullWidth
                value={row.license_class_three}
                onChange={handleOperatorTwo(
                  "license_class_three",
                  value.column.rowIndex
                )}
              />
            </Stack>
          );
        },
      },
      {
        key: "date_licensed_one",
        headerName: "Date Licensed",
        fieldName: "date_licensed_one",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOOperatorTwo) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date_licensed_one}
              onChange={(newValue) => {
                updateOperatorTwo(
                  "date_licensed_one",
                  value.column.rowIndex,
                  newValue
                );
              }}
            />
          );
        },
      },
      {
        key: "date_licensed_two",
        headerName: "Date Licensed",
        fieldName: "date_licensed_two",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOOperatorTwo) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date_licensed_two}
              onChange={(newValue) => {
                updateOperatorTwo(
                  "date_licensed_two",
                  value.column.rowIndex,
                  newValue
                );
              }}
            />
          );
        },
      },
      {
        key: "date_licensed_three",
        headerName: "Date Licensed",
        fieldName: "date_licensed_three",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOOperatorTwo) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date_licensed_three}
              onChange={(newValue) => {
                updateOperatorTwo(
                  "date_licensed_three",
                  value.column.rowIndex,
                  newValue
                );
              }}
            />
          );
        },
      },
    ],
    items: values.described_operators_two,
  };
  const updateConvictions = (key: string, rowIndex: number, value: any) => {
    const newValues = produce(
      values.convictions_during_the_past_years,
      (draftValues) => {
        //@ts-ignore
        draftValues[rowIndex][key] = value;
      }
    );
    setFieldValue("convictions_during_the_past_years", newValues);
  };

  const handleConvictions =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateConvictions(key, rowIndex, e.target.value);
    };

  const handleSelectConvictions =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateConvictions(key, rowIndex, e.target.value);
    };

  const describeConvictions: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 100,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "driver_no",
        headerName: "Driver No.",
        fieldName: "driver_no",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOConviction) => {
          return (
            <CustomTextField
              fullWidth
              value={row.driver_no}
              onChange={handleConvictions("driver_no", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "date1",
        headerName: "Date",
        fieldName: "date1",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOConviction) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date1}
              onChange={(newValue) => {
                updateConvictions("date1", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description1",
        headerName: "Description",
        fieldName: "description1",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOConviction) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description1}
              onChange={handleConvictions(
                "description1",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "date2",
        headerName: "Date",
        fieldName: "date2",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOConviction) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date2}
              onChange={(newValue) => {
                updateConvictions("date2", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description2",
        headerName: "Description",
        fieldName: "description2",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOConviction) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description2}
              onChange={handleConvictions(
                "description2",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "date3",
        headerName: "Date",
        fieldName: "date3",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOConviction) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date3}
              onChange={(newValue) => {
                updateConvictions("date3", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description3",
        headerName: "Description",
        fieldName: "description3",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOConviction) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description3}
              onChange={handleConvictions(
                "description3",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.convictions_during_the_past_years,
  };
  const updateAccidents = (key: string, rowIndex: number, value: any) => {
    const newValues = produce(
      values.convictions_during_the_past_years,
      (draftValues) => {
        //@ts-ignore
        draftValues[rowIndex][key] = value;
      }
    );
    setFieldValue("convictions_during_the_past_years", newValues);
  };

  const handleAccidents =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateAccidents(key, rowIndex, e.target.value);
    };

  const handleSelectAccidents =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateAccidents(key, rowIndex, e.target.value);
    };

  const describeAccidents: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 100,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "driver_no",
        headerName: "Driver No.",
        fieldName: "driver_no",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOAccidentsSuspension) => {
          return (
            <CustomTextField
              fullWidth
              value={row.driver_no}
              onChange={handleConvictions("driver_no", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "date1",
        headerName: "Date",
        fieldName: "date1",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOAccidentsSuspension) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date1}
              onChange={(newValue) => {
                updateConvictions("date1", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description1",
        headerName: "Description",
        fieldName: "description1",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOAccidentsSuspension) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description1}
              onChange={handleConvictions(
                "description1",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "date2",
        headerName: "Date",
        fieldName: "date2",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOAccidentsSuspension) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date2}
              onChange={(newValue) => {
                updateConvictions("date2", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description2",
        headerName: "Description",
        fieldName: "description2",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOAccidentsSuspension) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description2}
              onChange={handleConvictions(
                "description2",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "date3",
        headerName: "Date",
        fieldName: "date3",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOAccidentsSuspension) => {
          return (
            <CustomDatePicker
              fullWidth
              value={row.date3}
              onChange={(newValue) => {
                updateConvictions("date3", value.column.rowIndex, newValue);
              }}
            />
          );
        },
      },
      {
        key: "description3",
        headerName: "Description",
        fieldName: "description3",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOAccidentsSuspension) => {
          return (
            <CustomTextField
              fullWidth
              value={row.description3}
              onChange={handleConvictions(
                "description3",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.accidents_suspension_in_the_past_years,
  };

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      additional_interest_details: {
        address: data.address,
        city: data.city,
        country: data.country,
        province_or_state: data.state,
        postal_code: data.postalCode,
      },
    });
  };

  return (
    <StandadCard>
      <Typography variant="h3" sx={{ mt: 3 }} fontWeight={600}>
        Additional Interest
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={3}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.additioanl_interest === "Add"}
              onChange={(e) => {
                setFieldValue("additioanl_interest", "Add");
              }}
            />
            <Typography>Add</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.additioanl_interest === "Delete"}
              onChange={(e) => {
                setFieldValue("additioanl_interest", "Delete");
              }}
            />
            <Typography>Delete</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={1.8}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.additioanl_interest === "Change"}
              onChange={(e) => {
                setFieldValue("additioanl_interest", "Change");
              }}
            />
            <Typography>
              Change
              {values.additioanl_interest === "Change" && ":  Vehicle V.I.N."}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4.2}>
          {values.additioanl_interest === "Change" && (
            <CustomTextField
              name="additional_interest_vin_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.additional_interest_vin_number}
              onChange={handleChange}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
      <Grid item xs={12} lg={5}>
          <CustomFormLabel>Address</CustomFormLabel>
          <LocationAutoComplete
            id="additional_interest_details.address"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.additional_interest_details.address}
            onLocationChange={handleAddress}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Address 2</CustomFormLabel>
          <CustomTextField
            name="additional_interest_details.unit_or_suite"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.additional_interest_details.unit_or_suite}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Country</CustomFormLabel>
          <CustomTextField
            name="additional_interest_details.country"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.additional_interest_details.country}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <CustomFormLabel>City</CustomFormLabel>
          <CustomTextField
            name="additional_interest_details.city"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.additional_interest_details.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <CustomFormLabel>State</CustomFormLabel>
          <CustomTextField
            name="additional_interest_details.province_or_state"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.additional_interest_details.province_or_state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <CustomFormLabel>Postal Code</CustomFormLabel>
          <CustomTextField
            name="additional_interest_details.postal_code"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.additional_interest_details.postal_code}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Typography variant="h3" sx={{ mt: 3 }} fontWeight={600}>
        Nature of Interest
      </Typography>
      <Grid container>
        <Grid item xs={12} lg={3}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.nature_of_interest === "Lienholder"}
              onChange={(e) => {
                setFieldValue("nature_of_interest", "Lienholder");
              }}
            />
            <Typography>LIENHOLDER</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={3}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.nature_of_interest === "Lessor"}
              onChange={(e) => {
                setFieldValue("nature_of_interest", "Lessor");
              }}
            />
            <Typography>LESSOR</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={3}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.nature_of_interest === "Other"}
              onChange={(e) => {
                setFieldValue("nature_of_interest", "Other");
              }}
            />
            <CustomTextField
              name="nature_of_interest_text"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.nature_of_interest_text}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
      </Grid>

      <Typography variant="h3" sx={{ mt: 3 }} fontWeight={600}>
        Operator
      </Typography>
      <Grid container>
        <Grid item xs={12} lg={3}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.operator === "Add"}
              onChange={(e) => {
                setFieldValue("operator", "Add");
              }}
            />
            <Typography>Add</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.operator === "Delete"}
              onChange={(e) => {
                setFieldValue("operator", "Delete");
              }}
            />
            <Typography>Delete</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={1.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.operator === "Change"}
              onChange={(e) => {
                setFieldValue("operator", "Change");
              }}
            />
            <Typography>
              Change
              {values.operator === "Change" && ":  Name"}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2.6}>
          {values.operator === "Change" && (
            <CustomTextField
              name="operator_name"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.operator_name}
              onChange={handleChange}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={2}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.same_as_section_one === "Section One"}
              onChange={(e) => {
                setFieldValue("same_as_section_one", "Section One");
              }}
            />
            <Typography>Same As Section 1</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container mt={3}>
        <Grid item xs={12}>
          <DataTable {...describeOperatorOne} />
        </Grid>
      </Grid>
      <Grid container mt={3}>
        <Grid item xs={12}>
          <DataTable {...describeOperatorTwo} />
        </Grid>
      </Grid>
      <Grid container mt={3} spacing={2}>
        <Grid item xs={12} lg={6}>
          <CustomFormLabel>Occupation(where applicable)</CustomFormLabel>
          <CustomTextField
            name="occupation"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.occupation}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomFormLabel>Driver Training Course</CustomFormLabel>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Stack direction={"row"} alignItems={"center"}>
              <Checkbox
                checked={values.driver_training_course === "YES"}
                onChange={(e) => {
                  setFieldValue("driver_training_course", "YES");
                }}
              />
              <Typography>YES</Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <Checkbox
                checked={values.driver_training_course === "NO"}
                onChange={(e) => {
                  setFieldValue("driver_training_course", "NO");
                }}
              />
              <Typography>NO</Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography>Attach Driver Training Certificate</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>
            Current or Previous Insurance Company
          </CustomFormLabel>
          <CustomTextField
            name="current_or_previous_insurance"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.current_or_previous_insurance}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Policy Number</CustomFormLabel>
          <CustomTextField
            name="policy_number"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.policy_number}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Relation to Insured</CustomFormLabel>
          <CustomTextField
            name="relation_to_insured"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.relation_to_insured}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Typography variant="h3" sx={{ mt: 3 }} fontWeight={600}>
        CONVICTIONS DURING THE PAST 3 YEARS
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12}>
          <DataTable {...describeConvictions} />
        </Grid>
      </Grid>
      <Typography variant="h3" sx={{ mt: 3 }} fontWeight={600}>
        ACCIDENTS DURING THE PAST 3 YEARS
      </Typography>
      <Grid container mt={3}>
        <Grid item xs={12}>
          <DataTable {...describeAccidents} />
        </Grid>

        <Grid item xs={12}>
          <CustomFormLabel>Remarks</CustomFormLabel>
          <CustomTextField
            name="remarks"
            variant="outlined"
            size="small"
            multiline
            rows={3}
            type="text"
            fullWidth
            value={values.remarks}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Stack direction={"row"} spacing={2} marginTop={3}>
        <RoleBasedCustomButton
          moduleId={MODULE_IDS.AUTOMOBILE}
          variant="outlined"
          onClick={() => props.changeTab(1)}
        >
          Prev
        </RoleBasedCustomButton>
        {/* <RoleBasedCustomButton
          moduleId={MODULE_IDS.AUTOMOBILE}
          variant="contained"
          onClick={() => props.changeTab(3)}
        >
          Next
        </RoleBasedCustomButton> */}
      </Stack>
    </StandadCard>
  );
};
