/* eslint-disable react/jsx-pascal-case */
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layout/fullLayout/loadable/loadable";
import { OOPSError } from "../views/authentication/OopsError";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { ProtectedRoute } from "./ProtectedRoutes";
import { PublicRoute } from "./PublicRoute";
import { BusinessPolicyLayout } from "../views/customers/components/Policies/BusinessPolicy/BusinessPolicyLayout";
import { BusinessPolicyBasicDetails } from "../views/customers/components/Policies/BusinessPolicy/BusinessBasicDetails/BusinessPolicyBasicDetails";
import { CoverageRoot } from "../views/customers/components/Policies/BusinessPolicy/CoverageRoot";
import { QuestionnareRoot } from "../views/customers/components/Policies/BusinessPolicy/QuestionnareRoot";
import { ScheduleRoot } from "../views/customers/components/Policies/BusinessPolicy/ScheduleRoot";
import { OthersRoot } from "../views/customers/components/Policies/BusinessPolicy/OthersRoot";
import { CommAutomobilePolicyLayout } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutomobilePolicyLayout";
import { CommAutomobileBasicDetails } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoBasicDetails/CommAutoBasicDetails";
import { CommAutoDriverRoot } from "../views/customers/components/Policies/CommAutomobilePolicy/DriverRoot";
import { CommAutoVehicleRoot } from "../views/customers/components/Policies/CommAutomobilePolicy/VehicleRoot";
import { CommAutoGaragePlatesList } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoGaragePlate/CommAutoGaragePlateList";
import { CommAutoVehicleCoverageScheduleRoot } from "../views/customers/components/Policies/CommAutomobilePolicy/VehicleCoverageScheduleRoot";
import { HabitationalPolicyLayout } from "../views/customers/components/Policies/Habitational/HabitationalPolicyLayout";
import { HabitationalBasicDetails } from "../views/customers/components/Policies/Habitational/HabitationalBasicDetails/HabitationalBasicDetails";
import { HabitationalApplicationList } from "../views/customers/components/Policies/Habitational/HabitationalApplicationForm/HabitationalApplicationList";
import { LifeInsuranceBasicDetails } from "../views/customers/components/Policies/LifeInsurance/LifeInsuranceBasicDetails/LifeInsuranceBasicDetails";
import { LifeInsurancePolicyLayout } from "../views/customers/components/Policies/LifeInsurance/LifeInsurancePolicyLayout";
import { LifeInsuranceAdditionalInformationRoot } from "../views/customers/components/Policies/LifeInsurance/AdditionalInformationRoot";
import { CommAutomobileVehicle } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoVehicle/CommAutoVehicle";
import { AutomobilePolicyLayout } from "../views/customers/components/Policies/AutomobilePolicy/AutmobilePolicyLayout";
import { AutomobileBasicDetails } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileBasicDetails/AutomobileBasicDetails";
import { AutoDriverRoot } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileDriverRoot";
import { AutoVehicleRoot } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileVehicleRoot";
import { AutoVehicleCoverageScheduleRoot } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileVehicleCoverageSchedule";
import { CommAutoCSVRoot } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoCSVRoot";
import { AutomobileApplicationList } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileApplicationForm/AutomobileApplicationList";
import { AutomobileScheduleA } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileSchedule/AutomobileScheduleA/AutomobileScheduleA";
import { CommAutomobileScheduleA } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoSchedule/CommAutoScheduleA/CommAutoScheduleA";
import { CommAutomobileScheduleB } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoSchedule/CommAutoScheduleB/CommAutoScheduleB";
import { CommAutomobileScheduleC } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoSchedule/CommAutoScheduleC/CommAutoScheduleC";
import { MODULE_IDS, POLICIES } from "../constants/enums";
import { AutomobileVehicle } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileVehicle/AutomobileVehicle";
import { HabitationalBinderRoot } from "../views/customers/components/Policies/Habitational/BinderRoot";
import { HabitationalPolicyFormsRoot } from "../views/customers/components/Policies/Habitational/PolicyFormsRoot";
import { HabitationalCreateTask } from "../views/customers/components/Policies/Habitational/HabitationalBasicDetails/HabitationalTask/HabitationalTask";
import { HabitationalAttachmentList } from "../views/customers/components/Policies/Habitational/HabitationalBasicDetails/Attachment/AttachmentList";
import { CommAutoPolicyFormsRoot } from "../views/customers/components/Policies/CommAutomobilePolicy/PolicyFormRoot";
import { CommAutoApplicationRoot } from "../views/customers/components/Policies/CommAutomobilePolicy/ApplicationRoot";
import { AutomobilePolicyFormsRoot } from "../views/customers/components/Policies/AutomobilePolicy/PolicyFormRoot";
import { BusinessAttachmentList } from "../views/customers/components/Policies/BusinessPolicy/BusinessBasicDetails/Attachment/AttachmentList";
import { CSIOLIst } from "../views/csio/CSIO";
import { CommAutoCSIOCertificate } from "../views/customers/components/Policies/CommAutomobilePolicy/CommCSIOCertificate/CommCSIOCertificate";
import { CommAutoOPCF_28A } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoOPCF_28/CommAutoOPCF_28";
import { BusinessTaskActivity } from "../views/customers/components/Policies/BusinessPolicy/TaskActivity/TaskActivity";
import { CommAutoTaskActivity } from "../views/customers/components/Policies/CommAutomobilePolicy/TaskActivity/TaskActivity";
import { AutomobileTaskActivity } from "../views/customers/components/Policies/AutomobilePolicy/TaskActivity/TaskActivity";
import { PolicyFormsRoot } from "../views/customers/components/Policies/BusinessPolicy/PolicyFormsRoot";
import { MarketingRoot } from "../views/customers/components/Policies/BusinessPolicy/MarketingRoot";
import { BusinessNoteFormList } from "../views/customers/components/Policies/BusinessPolicy/BusinessNoteForm/BusinessNoteFormList";
import { CommAutoNoteFormList } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoNoteForm/CommAutoNoteFormList";
import { AutomobileNoteFormList } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileNoteForm/AutomobileNoteFormList";
import { HabitationalNoteFormList } from "../views/customers/components/Policies/Habitational/HabitationalNoteForm/HabitationalNoteFormList";
import { LifeInsuranceNoteFormList } from "../views/customers/components/Policies/LifeInsurance/LifeInsuranceNoteForm/LifeInsuranceNoteFormList";
import { LifeInsuranceCoverageNeed } from "../views/customers/components/Policies/LifeInsurance/LifeInsurancePolicies/CoverageNeed/LifeInsuranceCoverageNeed";
import { CommAutoMarketingRoot } from "../views/customers/components/Policies/CommAutomobilePolicy/MarketingRoot";
import { InsuraceReportsTabs } from "../views/tasks/InsuranceReports/InsurnceReportsTabs/InsuraceReportsTabs";
import { BusinessCancellation } from "../views/customers/components/Policies/BusinessPolicy/BusinessCancellation/BusinessCancellation";
import { AutomobileCancellation } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileCancellation/AutomobileCancellation";
import { CommAutoCancellation } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoCancellation/CommAutoCancellation";
import { HabitationalCancellation } from "../views/customers/components/Policies/Habitational/HabitationalCancellation/HabitationalCancellation";
import { LifeInsurancePolicyFormsRoot } from "../views/customers/components/Policies/LifeInsurance/PolicyformRoot";
import { LifeInsuranceCancellation } from "../views/customers/components/Policies/LifeInsurance/LifeInsuranceCancellation/LifeInsuranceCancellation";
import { RequestFormsList } from "../views/OnBoarding/RequestForms/RequestFormsList";
import { LifeInsurerClaim } from "../views/customers/components/Policies/LifeInsurance/LifeInsuranceBasicDetails/Claims/Claim";
import { AutomobileScheduleAList } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileSchedule/AutomobileScheduleA/AutomobileScheduleAList";
import { StaffDirectory } from "../views/StaffDirectory/StaffDirectoryList";
import { ViewTaskDetails } from "../views/tasks/BeforeCreate/ViewTaskDetails/ViewTaskDetails";
import { NoPolicyInsuranceReports } from "../views/tasks/InsuranceReports/NoPolicyInsuranceReports/NoPolicyInsuranceReports";
import { OldBusinessTracker } from "../views/tasks/BeforeCreate/OldBusinessTracker";
import { OldInsuranceReportsList } from "../views/tasks/InsuranceReports/OldInsuranceReportsList";

import { Documents } from "../views/g4sign/dcouments/Documents";
import { UpsertDocument } from "../views/g4sign/components/UpsertDocument/UpsertDocument";

import { NotesList } from "../views/customers/components/Policies/Notes/NotesList";
import { TaskNotesList } from "../views/tasks/TaskNote/TaskNotesList";
import { ManageTaskNote } from "../views/tasks/TaskNote/ManageTaskNote";
import { AutomobileAlbertaApplicationList } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileAlbertaApplicationForm/AutomobileAlbertaApplicationList";
import { CommAutomobileScheduleD } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoSchedule/CommAutoScheduleD/CommAutoScheduleD";
import { CSIOEdocs } from "../views/csio/CSIOEdocs";
import { CommAutomobileCsioApplicationList } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoCSIOApplication/CommAutoCSIOApplicationList";
import { CommAutomobileCsioApplicationForm } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoCSIOApplication/commAutoCsioApplicationStepTab";
import { ManageCSIOTabs } from "../views/csio/ManageCSIOTabs";
import { AutomobileCSIOEDocsList } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileCSIOEDocs/AutomobileCsioEDocsList";
import { HabitationalCSIOEDocsList } from "../views/customers/components/Policies/Habitational/HabitationalCSIOEDocs/HabitatioanlCsioEDocsList";
import { CommAutoCSIOEDocsList } from "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoCSIOEDOCS/CommAutoCsioEdocsList";
import { BusinessCSIOEDocsList } from "../views/customers/components/Policies/BusinessPolicy/BusinessCSIOEDocs/BusinessCsioEDocsList";
import { AutomobileCSIOApplicationTab } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileCSIOApplicationTab";
import { HabitationalRiskLocationForm } from "../views/customers/components/Policies/Habitational/HabitationalRiskLocation/HabitationalRiskLocation";
import { RiskLocationRoot } from "../views/customers/components/Policies/Habitational/RiskLocationRoot";
import { HabitationalCoveragesList } from "../views/customers/components/Policies/Habitational/HabitationalCoverages/HabitationalCoveragesList";
import { HabitationalCSIOApplicationTab } from "../views/customers/components/Policies/Habitational/HabitationalCsioApplicationTab";
import { AutomobilePolicyChangeReqForm } from "../views/customers/components/Policies/AutomobilePolicy/AutomobileCSIOPolicyChangeRequest/AutomobileCSIOPolicyChangeReqForm";
import { HabitationalPolicyChangeReqForm } from "../views/customers/components/Policies/Habitational/HabitationalPolicyChangeRequest/HabitationalPolicyChangeRequest";

// import {OrderInsuranceReport} from "../views/tasks/InsuranceReports/OrderInsuranceReport";

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() =>
    import("../layout/fullLayout/FullLayout").then(({ FullLayout }) => ({
      default: FullLayout,
    }))
  )
);
const BlankLayout = Loadable(
  lazy(() => import("../layout/blankLayout/BlankLayout"))
);
/* ***End Layouts**** */

const Error = Loadable(
  lazy(() =>
    import("../views/authentication/Error").then(({ Error }) => ({
      default: Error,
    }))
  )
);

/* ****Pages***** */

const Dashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/Dashboard/Dashbaord").then(({ Dashboard }) => ({
      default: Dashboard,
    }))
  )
);

const Login = Loadable(
  lazy(() =>
    import("../views/authentication/Login").then(({ Login }) => ({
      default: Login,
    }))
  )
);

const ForgotPassword = Loadable(
  lazy(() =>
    import("../views/authentication/ForgotPassword").then(
      ({ ForgotPassword }) => ({
        default: ForgotPassword,
      })
    )
  )
);

const AccessDenied = Loadable(
  lazy(() =>
    import("../views/authentication/AccessDenied").then(({ AccessDenied }) => ({
      default: AccessDenied,
    }))
  )
);

const Leads = Loadable(
  lazy(() =>
    import("../views/leads/Leads").then(({ Leads }) => ({
      default: Leads,
    }))
  )
);

const ManageLead = Loadable(
  lazy(() =>
    import("../views/leads/ManageLead").then(({ ManageLead }) => ({
      default: ManageLead,
    }))
  )
);

const InsurerComposer = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/InsurerCode/InsurerComposer"
    ).then(({ InsurerComposer }) => ({
      default: InsurerComposer,
    }))
  )
);

const Insurers = Loadable(
  lazy(() =>
    import("../views/insuranceCompany/Insurers").then(({ Insurers }) => ({
      default: Insurers,
    }))
  )
);

const SubInsurerComposer = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/SubInsurer/SubInsurerComposer"
    ).then(({ SubInsurerComposer }) => ({
      default: SubInsurerComposer,
    }))
  )
);

const SubInsurerList = Loadable(
  lazy(() =>
    import("../views/insuranceCompany/SubInsurerList").then(
      ({ SubInsurerList }) => ({
        default: SubInsurerList,
      })
    )
  )
);

const UnderWritersComposer = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/UnderWriters/UnderWritersComposer"
    ).then(({ UnderWritersComposer }) => ({
      default: UnderWritersComposer,
    }))
  )
);

const UnderWritersList = Loadable(
  lazy(() =>
    import("../views/insuranceCompany/UnderWritersList").then(
      ({ UnderWritersList }) => ({
        default: UnderWritersList,
      })
    )
  )
);

const BrokerCodeComposer = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/BrokerCode/BrokerCodeComposer"
    ).then(({ BrokerCodeComposer }) => ({
      default: BrokerCodeComposer,
    }))
  )
);

const BrokerCodeList = Loadable(
  lazy(() =>
    import("../views/insuranceCompany/BrokerCodeList").then(
      ({ BrokerCodeList }) => ({
        default: BrokerCodeList,
      })
    )
  )
);

const BrokerContactsList = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/BrokerContacts/BrokerContacts"
    ).then(({ BrokerContacts }) => ({
      default: BrokerContacts,
    }))
  )
);

const ManageBrokerContacts = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/BrokerContacts/ManageBrokerContact"
    ).then(({ ManageBrokerContact }) => ({
      default: ManageBrokerContact,
    }))
  )
);

const BranchOfficeComposer = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/BranchOffice/BranchOfficeComposer"
    ).then(({ BranchOfficeComposer }) => ({
      default: BranchOfficeComposer,
    }))
  )
);

const BranchOfficeList = Loadable(
  lazy(() =>
    import("../views/insuranceCompany/BranchOfficeList").then(
      ({ BranchOfficeList }) => ({
        default: BranchOfficeList,
      })
    )
  )
);

const InterestedPartyList = Loadable(
  lazy(() =>
    import("../views/insuranceCompany/InterestedPartyList").then(
      ({ InterestedPartyList }) => ({
        default: InterestedPartyList,
      })
    )
  )
);

const InterestedPartyComposer = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/InterestedParty/InterestedParty"
    ).then(({ InterestedParty }) => ({
      default: InterestedParty,
    }))
  )
);

const AdditionalInterestList = Loadable(
  lazy(() =>
    import("../views/insuranceCompany/AdditionalInteretList").then(
      ({ AdditionalInterestList }) => ({
        default: AdditionalInterestList,
      })
    )
  )
);

const AdditionalInterestComposer = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/AdditionalInterest/AdditionalInterest"
    ).then(({ AdditionalInterest }) => ({
      default: AdditionalInterest,
    }))
  )
);

const InsurerContactsComposer = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/InsurerContacts/InsurerContactsComposer"
    ).then(({ InsurerContactsComposer }) => ({
      default: InsurerContactsComposer,
    }))
  )
);

const InsurerContactsList = Loadable(
  lazy(() =>
    import("../views/insuranceCompany/InsurerContactsList").then(
      ({ InsurerContactsList }) => ({
        default: InsurerContactsList,
      })
    )
  )
);

const PolicyCommissionComposer = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/PolicyCommission/PolicyCommissionComposer"
    ).then(({ PolicyCommissionComposer }) => ({
      default: PolicyCommissionComposer,
    }))
  )
);

const PolicyCommissionList = Loadable(
  lazy(() =>
    import("../views/insuranceCompany/PolicyCommissionList").then(
      ({ PolicyCommissionList }) => ({
        default: PolicyCommissionList,
      })
    )
  )
);

const CsioCompanyComposer = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/CsioCompany/CsioCompanyComposer"
    ).then(({ CsioCompanyComposer }) => ({
      default: CsioCompanyComposer,
    }))
  )
);

const CsioCompanyList = Loadable(
  lazy(() =>
    import("../views/insuranceCompany/CsioCompanyList").then(
      ({ CsioCompanyList }) => ({
        default: CsioCompanyList,
      })
    )
  )
);

const ViewOpportunites = Loadable(
  lazy(() =>
    import("../views/opportunites/opportunites").then(
      ({ ViewOpportunites }) => ({
        default: ViewOpportunites,
      })
    )
  )
);

const CustomersList = Loadable(
  lazy(() =>
    import("../views/customers/CustomersList").then(({ CustomersList }) => ({
      default: CustomersList,
    }))
  )
);

const EditCustomer = Loadable(
  lazy(() =>
    import("../views/customers/EditCustomer").then(({ EditCustomer }) => ({
      default: EditCustomer,
    }))
  )
);

const CreateCustomer = Loadable(
  lazy(() =>
    import("../views/customers/CreateCustomer").then(({ CreateCustomer }) => ({
      default: CreateCustomer,
    }))
  )
);

const CustomerOverview = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/CustomerOverview/CustomerOverview"
    ).then(({ CustomerOverview }) => ({
      default: CustomerOverview,
    }))
  )
);

const BusinessClaim = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/BusinessBasicDetails/Claim/Claim"
    ).then(({ Claim }) => ({
      default: Claim,
    }))
  )
);

const PoliciesList = Loadable(
  lazy(() =>
    import("../views/Policies/PoliciesList").then(({ PoliciesList }) => ({
      default: PoliciesList,
    }))
  )
);

const FineArt = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/FineArt/FineArt"
    ).then(({ FineArt }) => ({
      default: FineArt,
    }))
  )
);

const RiskLocation = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/RiskLocation/RiskLocation"
    ).then(({ RiskLocation }) => ({
      default: RiskLocation,
    }))
  )
);

const ContractorQuestionare = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/Contractor/ContractorQuestionare"
    ).then(({ ContractorQuestionare }) => ({
      default: ContractorQuestionare,
    }))
  )
);

const ResturantQuestionare = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/ResturantQuestionare/ResturantQuestionare"
    ).then(({ ResturantQuestionare }) => ({
      default: ResturantQuestionare,
    }))
  )
);

const ToolFloater = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/ToolFloater/ToolFloater"
    ).then(({ ToolFloater }) => ({
      default: ToolFloater,
    }))
  )
);

const Coverage = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/Coverage/Coverage"
    ).then(({ Coverage }) => ({
      default: Coverage,
    }))
  )
);

const Equipment = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/Equipment/Equipment"
    ).then(({ Equipment }) => ({
      default: Equipment,
    }))
  )
);

const Interested = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/InterestedParties/InterestedParties"
    ).then(({ InterestedParties }) => ({
      default: InterestedParties,
    }))
  )
);
const HabitationalInterested = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/InterestedParties/InterestedParties"
    ).then(({ InterestedParties }) => ({
      default: InterestedParties,
    }))
  )
);
const HabitationalRiskLocation = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalRiskLocation/HabitationalRiskLocation"
    ).then(({ HabitationalRiskLocationForm }) => ({
      default: HabitationalRiskLocationForm,
    }))
  )
);

const Additional = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/AdditionalInterest/AdditionalInterest"
    ).then(({ Additional }) => ({
      default: Additional,
    }))
  )
);

const Premium = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/PremiumFinance/PremiumFinance"
    ).then(({ PremiumFinance }) => ({
      default: PremiumFinance,
    }))
  )
);

const Authorization = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/AuthorizationForm/AuthorizationForm"
    ).then(({ Authorization }) => ({
      default: Authorization,
    }))
  )
);

const Subscription = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/Subscription/Subscription"
    ).then(({ Subscription }) => ({
      default: Subscription,
    }))
  )
);

const UnderLying = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/UnderLying/UnderLying"
    ).then(({ UnderLying }) => ({
      default: UnderLying,
    }))
  )
);

// COMMERCIAL AUTOMOBILE ROUTING ----------------

const CommAutoHistory = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoBasicDetails/CommAutoHistory/CommAutoHistory"
    ).then(({ CommAutoHistory }) => ({
      default: CommAutoHistory,
    }))
  )
);

const CommAutoDriverInformation = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoDriver/CommAutoDriverInformation/CommAutoDriverInformation"
    ).then(({ CommAutomobileDriverInformation }) => ({
      default: CommAutomobileDriverInformation,
    }))
  )
);

const CommAutoDriverClaims = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoDriver/CommAutoDriverClaims/CommAutoDriverClaims"
    ).then(({ CommAutomobileClaimInformation }) => ({
      default: CommAutomobileClaimInformation,
    }))
  )
);

const CommAutoDriverConviction = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoDriver/CommAutoDriverConviction/CommAutoDriverConviction"
    ).then(({ CommAutomobileConvictionInformation }) => ({
      default: CommAutomobileConvictionInformation,
    }))
  )
);

const CommAutoDriverActivityTask = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoDriver/CommAutoDriverActivity/CommAutoDriver"
    ).then(({ CommAutomobileActivityInformation }) => ({
      default: CommAutomobileActivityInformation,
    }))
  )
);

const CommAutoVehicleInformation = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoVehicle/CommAutoVehicleInformation/CommAutoVehicleInformation"
    ).then(({ CommAutomobileVehicleInformation }) => ({
      default: CommAutomobileVehicleInformation,
    }))
  )
);

const CommAutoAdditionalInformation = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoVehicle/CommAutoVehicleAdditional/CommAutoVehicleAdditional"
    ).then(({ CommAutomobileAdditionalInformation }) => ({
      default: CommAutomobileAdditionalInformation,
    }))
  )
);

const CommAutoInterestedParty = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoVehicle/CommAutoVehicleInterested/CommAutoVehicleInterested"
    ).then(({ CommAutomobileInterested }) => ({
      default: CommAutomobileInterested,
    }))
  )
);

const CommAutoGaragePlate = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoGaragePlate/CommAutoGaragePlateDialog"
    ).then(({ CommAutoGaragePlatesDialog }) => ({
      default: CommAutoGaragePlatesDialog,
    }))
  )
);

const CommAutomobileAuthorization = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoAuthorizationForm/CommAutoAuthorizationForm"
    ).then(({ CommAutomobileAuthorization }) => ({
      default: CommAutomobileAuthorization,
    }))
  )
);

const CommAutoCSV = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoCSV/CommAutoCSV"
    ).then(({ CommAutomobileCSV }) => ({
      default: CommAutomobileCSV,
    }))
  )
);

const CommAutomobileAttachmentList = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoBasicDetails/Attachment/AttachmentList"
    ).then(({ CommAutomobileAttachmentList }) => ({
      default: CommAutomobileAttachmentList,
    }))
  )
);

const CommAutomobileApplicationList = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoApplication/CommAutoApplicationList"
    ).then(({ CommAutomobileApplicationList }) => ({
      default: CommAutomobileApplicationList,
    }))
  )
);

const CommAutomobileLiabilitySlips = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoLiabilitySlips/CommAutoLiabilitySlips"
    ).then(({ CommAutoLiabilitySlips }) => ({
      default: CommAutoLiabilitySlips,
    }))
  )
);

const CommAutomobileLiabilityInsurance = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommCSIOCertificate/CommCSIOCertificate"
    ).then(({ CommAutoCSIOCertificate }) => ({
      default: CommAutoCSIOCertificate,
    }))
  )
);

const CommAutomobileOPCF16 = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoOPCF_16/CommAutoOPCF_16"
    ).then(({ CommAutoOPCF_16 }) => ({
      default: CommAutoOPCF_16,
    }))
  )
);
const CommAutomobileConsentForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoConsentForm/CommAutoConsentForm"
    ).then(({ CommAutoConsentForm }) => ({
      default: CommAutoConsentForm,
    }))
  )
);
const CommAutomobileRetireeDiscount = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoRetireeDiscount/CommAutoRetireeDiscount"
    ).then(({ CommAutoRetireeDiscount }) => ({
      default: CommAutoRetireeDiscount,
    }))
  )
);
const CommAutomobileGarageForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoGarageForm/CommAutoGarageForm"
    ).then(({ CommAutoGarageForm }) => ({
      default: CommAutoGarageForm,
    }))
  )
);
const CommAutomobileOwnerForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoOwnerForm/CommAutoOwnerForm"
    ).then(({ CommAutoOwnerForm }) => ({
      default: CommAutoOwnerForm,
    }))
  )
);
// const CommAutomobileSupplementaryForm = Loadable(
//   lazy(() =>
//     import(
//       "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoSupplementaryForm/CommAutoSupplementaryTab"
//     ).then(({ CommAutoSupplementaryForm }) => ({
//       default: CommAutoSupplementaryForm,
//     }))
//   )
// );
const CommAutoSupplementaryFormTab = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoSupplementaryForm/CommAutoSupplementaryTab"
    ).then(({ CommAutoSupplementaryForm }) => ({
      default: CommAutoSupplementaryForm,
    }))
  )
);
const CommAutomobileSharingFillable = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoSharingFillable/CommAutoSharingFillable"
    ).then(({ CommAutoSharingFillable }) => ({
      default: CommAutoSharingFillable,
    }))
  )
);
const CommAutomobileDwellingDeclaration = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoDwellingDeclaration/CommAutoDwellingDeclaration"
    ).then(({ CommAutoDwellingDeclaration }) => ({
      default: CommAutoDwellingDeclaration,
    }))
  )
);
const CommAutomobileDwelling = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoDwelling/CommAutoDwelling"
    ).then(({ CommAutoDwelling }) => ({
      default: CommAutoDwelling,
    }))
  )
);

const CommAutomobileOPCF17 = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoOPCF_17/CommAutoOPCF_17"
    ).then(({ CommAutoOPCF_17 }) => ({
      default: CommAutoOPCF_17,
    }))
  )
);

const CommAutomobileOPCF28A = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoOPCF_28/CommAutoOPCF_28"
    ).then(({ CommAutoOPCF_28A }) => ({
      default: CommAutoOPCF_28A,
    }))
  )
);

const CommAutomobileApplicationForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoApplication/CommAutoApplication"
    ).then(({ CommAutomobileApplicationForm }) => ({
      default: CommAutomobileApplicationForm,
    }))
  )
);

const CommAutomobileAddMarketing = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoMarketing/AddMarketing/CommAutoAddMarketing"
    ).then(({ CommAutomobileAddMarketingHistory }) => ({
      default: CommAutomobileAddMarketingHistory,
    }))
  )
);

const CommAutomobileGarageApplicationOAF4 = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoInsuranceGarageApplication/CommAutoInsuranceGarageApplication"
    ).then(({ CommAutomobileInsuranceGarageApplication }) => ({
      default: CommAutomobileInsuranceGarageApplication,
    }))
  )
);

// --------------- AUTOMOBILE ROUTING ----------------

const AutomobilePolicy = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobilePolicy"
    ).then(({ AutomobilePolicy }) => ({
      default: AutomobilePolicy,
    }))
  )
);

const AutomobileInterested = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileVehicle/AutomobileInterested/AutomobileInterested"
    ).then(({ AutomobileInterested }) => ({
      default: AutomobileInterested,
    }))
  )
);

const AutomobileAdditional = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileVehicle/AutomobileAdditional/AutomobileAdditional"
    ).then(({ AutomobileAdditionalInformation }) => ({
      default: AutomobileAdditionalInformation,
    }))
  )
);

const AutomobileDriverInformation = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileDriver/AutomobileDriverInformation/AutomobileDriverInformation"
    ).then(({ AutomobileDriverInformation }) => ({
      default: AutomobileDriverInformation,
    }))
  )
);

const AutomobileDriverClaim = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileDriver/AutomobileDriverClaims/AutomobileDriverClaims"
    ).then(({ AutomobileClaimInformation }) => ({
      default: AutomobileClaimInformation,
    }))
  )
);

const AutomobileDriverConviction = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileDriver/AutomobileDriverConviction/AutomobileDriverConviction"
    ).then(({ AutomobileConvictionInformation }) => ({
      default: AutomobileConvictionInformation,
    }))
  )
);

const AutomobileDriverActivity = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileDriver/AutomobileDriverActivity/AutomobileDriverActivity"
    ).then(({ AutomobileActivityInformation }) => ({
      default: AutomobileActivityInformation,
    }))
  )
);

const AutomobileAuthorizationList = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileAuthorizationForm/AuthorizationFormList"
    ).then(({ AutomobileAuthorizationList }) => ({
      default: AutomobileAuthorizationList,
    }))
  )
);

const AutomobileAuthorization = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileAuthorizationForm/AuthorizationForm"
    ).then(({ AutomobileAuthorization }) => ({
      default: AutomobileAuthorization,
    }))
  )
);

const AutomobileApplicationForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileApplicationForm/AutomobileApplicationStepsTab"
    ).then(({ AutomobileApplicationForm }) => ({
      default: AutomobileApplicationForm,
    }))
  )
);
const AutomobileCSIOApplicationForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileCSIOApplicationForm/AutomobileCSIOApplicationStepTab"
    ).then(({ AutomobileCSIOApplicationForm }) => ({
      default: AutomobileCSIOApplicationForm,
    }))
  )
);

const AutomobileAlbertaApplicationForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileAlbertaApplicationForm/AutomobileAlbertaApplicationStepsTab"
    ).then(({ AutomobileAlbertaApplicationForm }) => ({
      default: AutomobileAlbertaApplicationForm,
    }))
  )
);

const AutomobileLiabilitySLips = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/LiabilitySlips/LiabilitySlips"
    ).then(({ LiabilitySlips }) => ({
      default: LiabilitySlips,
    }))
  )
);

const AutomobileCSIOCertificat = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/CSIOCertificate/CSIOCertificate"
    ).then(({ CSIOCertificate }) => ({
      default: CSIOCertificate,
    }))
  )
);

const AutomobileOPCF16 = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/OPCF_16/OPCF_16"
    ).then(({ OPCF_16 }) => ({
      default: OPCF_16,
    }))
  )
);

const AutomobileOPCF17 = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/OPCF_17/OPCF_17"
    ).then(({ OPCF_17 }) => ({
      default: OPCF_17,
    }))
  )
);

const AutomobileOPCF28A = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/OPCF_28A/OPCF_28A"
    ).then(({ OPCF_28A }) => ({
      default: OPCF_28A,
    }))
  )
);

const AutomobileAttachmentList = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileBasicDetails/Attachment/AttachmentList"
    ).then(({ AutomobileAttachmentList }) => ({
      default: AutomobileAttachmentList,
    }))
  )
);

// HABITATIONAL ---------------------------------------

const HabitationalClaim = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalClaim/Claim"
    ).then(({ HabitationalClaim }) => ({
      default: HabitationalClaim,
    }))
  )
);
const HabitationalHistory = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalBasicDetails/HabitationalHistory/HabitationalHistory"
    ).then(({ HabitationalHistory }) => ({
      default: HabitationalHistory,
    }))
  )
);

const HabitationalApplicationTabs = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalApplicationForm/HabitationalApplicationStepTabs"
    ).then(({ HabitationalApplicationStepTab }) => ({
      default: HabitationalApplicationStepTab,
    }))
  )
);
const HabitationalCSIOApplicationTabs = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalCsioApplicationForm/HabitationalCsioApplicationStepTabs"
    ).then(({ HabitationalCsioApplicationUI }) => ({
      default: HabitationalCsioApplicationUI,
    }))
  )
);

const HabitationalPaymentAUthortization = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalPaymentAuthorization/HabitationalPaymentAuthorization"
    ).then(({ HabitationalPaymentAuthorization }) => ({
      default: HabitationalPaymentAuthorization,
    }))
  )
);

const HabitationalBinderCsio = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalBinderCSIO/HabitationalBinderCSIO"
    ).then(({ HabitationalBinderCSIO }) => ({
      default: HabitationalBinderCSIO,
    }))
  )
);

const HabitationalBinderNonCsio = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalBinderNonCSIO/HabitationalBinderNonCSIO"
    ).then(({ HabitationalBinderNonCSIO }) => ({
      default: HabitationalBinderNonCSIO,
    }))
  )
);

// -----------------------------------------------TASK ---------------------------------------
const CreateTasks = Loadable(
  lazy(() =>
    import("../views/tasks/task").then(({ Task }) => ({
      default: Task,
    }))
  )
);

const TaskCalender = Loadable(
  lazy(() =>
    import("../views/tasks/TaskCalender/TaskCalender").then(
      ({ TaskCalender }) => ({
        default: TaskCalender,
      })
    )
  )
);

const BeforeCreate = Loadable(
  lazy(() =>
    import("../views/tasks/BeforeCreate/BeforeCreate").then(
      ({ BeforeCreateTask }) => ({
        default: BeforeCreateTask,
      })
    )
  )
);
const InsuranceReport = Loadable(
  lazy(() =>
    import("../views/tasks/InsuranceReports/InsuranceReportLIst").then(
      ({ InsuranceReport }) => ({
        default: InsuranceReport,
      })
    )
  )
);
const OrderInsuranceReport = Loadable(
  lazy(() =>
    import("../views/tasks/InsuranceReports/OrderInsuranceReport").then(
      ({ OrderInsuranceReport }) => ({
        default: OrderInsuranceReport,
      })
    )
  )
);
const TaskBoard = Loadable(
  lazy(() =>
    import("../views/tasks/TaskBoard/TasksBoard").then(({ TaskBoard }) => ({
      default: TaskBoard,
    }))
  )
);

const BeforeProfile = Loadable(
  lazy(() =>
    import("../views/Profile/UsersList/UsersList").then(
      ({ BeforeProfile }) => ({
        default: BeforeProfile,
      })
    )
  )
);

const ManageUser = Loadable(
  lazy(() =>
    import("../views/Profile/UsersList/ManageUser").then(({ ManageUser }) => ({
      default: ManageUser,
    }))
  )
);

const ViewProfile = Loadable(
  lazy(() =>
    import("../views/Profile/ViewProfile/ViewProfile").then(
      ({ ViewProfile }) => ({
        default: ViewProfile,
      })
    )
  )
);

const MyProfileView = Loadable(
  lazy(() =>
    import("../views/Profile/UsersList/MyProfileView").then(
      ({ MyProfileView }) => ({
        default: MyProfileView,
      })
    )
  )
);

const CertificateLiabilityInsurance = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/CSIOCertificate/CSIOCertificate"
    ).then(({ CSIOCertificate }) => ({ default: CSIOCertificate }))
  )
);

const CertificatePropertyInsurance = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/PropertyInsuranceCertificate/PropertyInsuranceCertificate"
    ).then(({ PropertyInsuranceCertificate }) => ({
      default: PropertyInsuranceCertificate,
    }))
  )
);

const CertificateGarageInsuranceInsurance = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/GarageCertificate/GarageCertificate"
    ).then(({ GarageCertificate }) => ({ default: GarageCertificate }))
  )
);

const AddMarketingHistory = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/Marketing/AddMarketing/AddMarketingHistory"
    ).then(({ AddMarketingHistory }) => ({ default: AddMarketingHistory }))
  )
);

const BusinessActivityList = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/BusinessBasicDetails/BusinessTask/BusinessTaskList"
    ).then(({ BusinessActivityList }) => ({
      default: BusinessActivityList,
    }))
  )
);

const LiabilitySlips = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/LiabilitySlips/LiabilitySlips"
    ).then(({ LiabilitySlips }) => ({ default: LiabilitySlips }))
  )
);

const OPCF_28A = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/OPCF_28A/OPCF_28A"
    ).then(({ OPCF_28A }) => ({ default: OPCF_28A }))
  )
);

const OPCF_16 = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/OPCF_16/OPCF_16"
    ).then(({ OPCF_16 }) => ({ default: OPCF_16 }))
  )
);

const OPCF_17 = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/OPCF_17/OPCF_17"
    ).then(({ OPCF_17 }) => ({ default: OPCF_17 }))
  )
);

const Email = Loadable(
  lazy(() =>
    import("../views/email/Email").then(({ EmailUI }) => ({ default: EmailUI }))
  )
);

const SecurityDashboard = Loadable(
  lazy(() =>
    import("../views/Security/CreateSecurityGroup").then(
      ({ CreateSecurityGroup }) => ({ default: CreateSecurityGroup })
    )
  )
);

const ListSecurityGroups = Loadable(
  lazy(() =>
    import("../views/Security/ListSecurityGroups").then(
      ({ ListSecurityGroups }) => ({ default: ListSecurityGroups })
    )
  )
);

const ViewInsurerContact = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/ViewsBrokerage/ViewInsurerContact"
    ).then(({ ViewInsurerContact }) => ({ default: ViewInsurerContact }))
  )
);
const ViewInsurerSubTable = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/ViewsBrokerage/ViewInsurerSubTable"
    ).then(({ ViewInsurerSubTable }) => ({ default: ViewInsurerSubTable }))
  )
);
const ViewPolicyCommission = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/ViewsBrokerage/ViewPolicyCommission"
    ).then(({ ViewPolicyCommission }) => ({ default: ViewPolicyCommission }))
  )
);
const ViewUnderWriter = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/ViewsBrokerage/ViewUnderWriter"
    ).then(({ ViewUnderWriter }) => ({ default: ViewUnderWriter }))
  )
);
const ViewInsurer = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/ViewsBrokerage/ViewInsurer"
    ).then(({ ViewInsurer }) => ({ default: ViewInsurer }))
  )
);
const ViewCSIOCompany = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/ViewsBrokerage/ViewCSIOCompany"
    ).then(({ ViewCSIOCompany }) => ({ default: ViewCSIOCompany }))
  )
);
const ViewBrokerCode = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/ViewsBrokerage/ViewBrokerCode"
    ).then(({ ViewBrokerCode }) => ({ default: ViewBrokerCode }))
  )
);
const ViewBranchOffice = Loadable(
  lazy(() =>
    import(
      "../views/insuranceCompany/components/ViewsBrokerage/ViewBranchOffice"
    ).then(({ ViewBranchOffice }) => ({ default: ViewBranchOffice }))
  )
);

const ViewTaskStep1 = Loadable(
  lazy(() =>
    import("../views/tasks/ViewTask/ViewTaskStep1").then(
      ({ ViewTaskStep_1 }) => ({ default: ViewTaskStep_1 })
    )
  )
);

const ViewInsurerReport = Loadable(
  lazy(() =>
    import("../views/tasks/ViewTask/ViewInsurerReport").then(
      ({ ViewInsurerReport }) => ({ default: ViewInsurerReport })
    )
  )
);

const ViewInsurerContactList = Loadable(
  lazy(() =>
    import("../views/insuranceCompany/InsurerContactsList").then(
      ({ InsurerContactsList }) => ({ default: InsurerContactsList })
    )
  )
);

const CreateOpportunites = Loadable(
  lazy(() =>
    import("../views/opportunites/CreateOppurtunity/CreateOppurtunity").then(
      ({ CreateOpportunites }) => ({ default: CreateOpportunites })
    )
  )
);

const TemplatesList = Loadable(
  lazy(() =>
    import("../views/Templates/TemplatesList").then(({ TemplatesList }) => ({
      default: TemplatesList,
    }))
  )
);

const MessagingTemplate = Loadable(
  lazy(() =>
    import("../views/Templates/Template").then(({ MessagingTemplate }) => ({
      default: MessagingTemplate,
    }))
  )
);

const OnBoardingList = Loadable(
  lazy(() =>
    import("../views/OnBoarding/onBoardStep").then(({ OnBoardStep }) => ({
      default: OnBoardStep,
    }))
  )
);

const OffBoarding = Loadable(
  lazy(() =>
    import("../views/OnBoarding/Offboarding/Offboarding").then(
      ({ OffBoarding }) => ({
        default: OffBoarding,
      })
    )
  )
);

const RenewalList = Loadable(
  lazy(() =>
    import("../views/tasks/Renewal/RenewalList").then(({ RenewalList }) => ({
      default: RenewalList,
    }))
  )
);

const TaskBinderList = Loadable(
  lazy(() =>
    import("../views/tasks/Binder/Binder").then(({ TaskBinderList }) => ({
      default: TaskBinderList,
    }))
  )
);
const BinderList = Loadable(
  lazy(() =>
    import("../views/tasks/Binder/BinderList").then(({ BinderList }) => ({
      default: BinderList,
    }))
  )
);

const TaskMarketingsList = Loadable(
  lazy(() =>
    import("../views/tasks/MarketingList/MarketingList").then(
      ({ TaskMarketingsList }) => ({
        default: TaskMarketingsList,
      })
    )
  )
);

const HabitationalMortgagee = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/habitationalMortgagee/habitationalMortgagee"
    ).then(({ MortgageeLossPayee }) => ({
      default: MortgageeLossPayee,
    }))
  )
);

const AccountingCompany = Loadable(
  lazy(() =>
    import("../views/Accounting/Company/Company").then(
      ({ AccountingCompany }) => ({
        default: AccountingCompany,
      })
    )
  )
);

const ChartOfAccount = Loadable(
  lazy(() =>
    import("../views/Accounting/ChartOfAccount/ChartsOfAccount").then(
      ({ ChartOfAccount }) => ({
        default: ChartOfAccount,
      })
    )
  )
);

const ChartOfAccountList = Loadable(
  lazy(() =>
    import("../views/Accounting/ChartOfAccount/ChartsOfAccountList").then(
      ({ ChartsOfAccountList }) => ({
        default: ChartsOfAccountList,
      })
    )
  )
);

const AccountingRecievable = Loadable(
  lazy(() =>
    import("../views/Accounting/Recievable/Recievable/Recievable").then(
      ({ AccountingRecievable }) => ({
        default: AccountingRecievable,
      })
    )
  )
);

const AccountingRecievableTrust = Loadable(
  lazy(() =>
    import(
      "../views/Accounting/Recievable/RecievableTrust/RecievableTrust"
    ).then(({ RecievableTrust }) => ({
      default: RecievableTrust,
    }))
  )
);

const AccountingRecievableTabs = Loadable(
  lazy(() =>
    import("../views/Accounting/Recievable/RecievableTabs").then(
      ({ AccountingRecievableTabs }) => ({
        default: AccountingRecievableTabs,
      })
    )
  )
);

const AccountingCompanyDetail = Loadable(
  lazy(() =>
    import("../views/Accounting/CompanyDetail/CompanyDetail").then(
      ({ CompanyDetail }) => ({
        default: CompanyDetail,
      })
    )
  )
);

const AccountingCompanyOnboarding = Loadable(
  lazy(() =>
    import("../views/Accounting/CompanyOnboarding/CompanyOnboarding").then(
      ({ CompanyOnboarding }) => ({
        default: CompanyOnboarding,
      })
    )
  )
);

const AccountingTaxInfo = Loadable(
  lazy(() =>
    import("../views/Accounting/TaxInfo/TaxInfo").then(
      ({ AccountingTaxInfo }) => ({
        default: AccountingTaxInfo,
      })
    )
  )
);

const AccountingEft = Loadable(
  lazy(() =>
    import("../views/Accounting/Payment/Eft/Eft").then(({ AccountingEft }) => ({
      default: AccountingEft,
    }))
  )
);

const AccountingAccountPayable = Loadable(
  lazy(() =>
    import("../views/Accounting/Payment/AccountPayable/AccountPayable").then(
      ({ AccountPayable }) => ({
        default: AccountPayable,
      })
    )
  )
);

const AccountingCheque = Loadable(
  lazy(() =>
    import("../views/Accounting/Cheque/Cheque").then(
      ({ AccountingCheque }) => ({
        default: AccountingCheque,
      })
    )
  )
);

const AccountingInteracEmailTransfer = Loadable(
  lazy(() =>
    import(
      "../views/Accounting/Payment/InteracEmailTransfer/InteracEmailTransfer"
    ).then(({ InteracEmailTransfer }) => ({
      default: InteracEmailTransfer,
    }))
  )
);

const AccountingPayment = Loadable(
  lazy(() =>
    import("../views/Accounting/Payment/Payment/Payment").then(
      ({ AccountingPayment }) => ({
        default: AccountingPayment,
      })
    )
  )
);

const AccountingTransactions = Loadable(
  lazy(() =>
    import("../views/Accounting/Transactions/Transactions").then(
      ({ AccountingTransactions }) => ({
        default: AccountingTransactions,
      })
    )
  )
);

const AccountingTransactionList = Loadable(
  lazy(() =>
    import("../views/Accounting/Transactions/TransactionList").then(
      ({ AccountingTransactionList }) => ({
        default: AccountingTransactionList,
      })
    )
  )
);

const InvoiceTransactionType = Loadable(
  lazy(() =>
    import(
      "../views/Accounting/InvoiceInformation/TransactionTypes/TransactionTypes"
    ).then(({ TransactionsType }) => ({
      default: TransactionsType,
    }))
  )
);

const InvoiceTransactionDescription = Loadable(
  lazy(() =>
    import(
      "../views/Accounting/InvoiceInformation/TransactionDescription/TransactionDescription"
    ).then(({ TransactionDescription }) => ({
      default: TransactionDescription,
    }))
  )
);

const AccountingTransactionTypesList = Loadable(
  lazy(() =>
    import(
      "../views/Accounting/InvoiceInformation/TransactionTypes/TransactionTypesList"
    ).then(({ TransactionTypesList }) => ({
      default: TransactionTypesList,
    }))
  )
);

const AccountingTransactionDescriptionList = Loadable(
  lazy(() =>
    import(
      "../views/Accounting/InvoiceInformation/TransactionDescription/TransactionDescriptionList"
    ).then(({ TransactionDescriptionList }) => ({
      default: TransactionDescriptionList,
    }))
  )
);

const AccountingInformation = Loadable(
  lazy(() =>
    import(
      "../views/Accounting/AccountingInformation/AccountingInformation"
    ).then(({ AccountingInformation }) => ({
      default: AccountingInformation,
    }))
  )
);

const AccountingInformationList = Loadable(
  lazy(() =>
    import(
      "../views/Accounting/AccountingInformation/AccountingInformationList"
    ).then(({ AccountingInformationList }) => ({
      default: AccountingInformationList,
    }))
  )
);

const AccountingInvoiceInformation = Loadable(
  lazy(() =>
    import("../views/Accounting/InvoiceInformation/InvoiceInformation").then(
      ({ InvoiceInformation }) => ({
        default: InvoiceInformation,
      })
    )
  )
);

const AccountingInvoiceInformationList = Loadable(
  lazy(() =>
    import(
      "../views/Accounting/InvoiceInformation/InvoiceInformationList"
    ).then(({ AccountingInvoiceInformationList }) => ({
      default: AccountingInvoiceInformationList,
    }))
  )
);

const AccountingPaymentList = Loadable(
  lazy(() =>
    import("../views/Accounting/Payment/Payment/PaymentList").then(
      ({ AccountingPaymentList }) => ({
        default: AccountingPaymentList,
      })
    )
  )
);

const AccountingChequeList = Loadable(
  lazy(() =>
    import("../views/Accounting/Cheque/ChequeList").then(
      ({ AccountingChequeList }) => ({
        default: AccountingChequeList,
      })
    )
  )
);

const InvoiceTemplate = Loadable(
  lazy(() =>
    import("../views/Accounting/InvoiceInformation/Template/Template").then(
      ({ InvoiceTemplate }) => ({
        default: InvoiceTemplate,
      })
    )
  )
);

const BillTemplate = Loadable(
  lazy(() =>
    import("../views/Accounting/Bill/Template/template").then(
      ({ BillTemplate }) => ({
        default: BillTemplate,
      })
    )
  )
);

const AccountingBillList = Loadable(
  lazy(() =>
    import("../views/Accounting/Bill/BillList").then(
      ({ AccountingBillList }) => ({
        default: AccountingBillList,
      })
    )
  )
);

const AccountingBill = Loadable(
  lazy(() =>
    import("../views/Accounting/Bill/Bill").then(({ AccountingBill }) => ({
      default: AccountingBill,
    }))
  )
);

const AccountingBillPaymentPayableList = Loadable(
  lazy(() =>
    import("../views/Accounting/Bill/PaymentPayable/PaymentPayableList").then(
      ({ AccountingBillPaymentPayableList }) => ({
        default: AccountingBillPaymentPayableList,
      })
    )
  )
);

const AccountingBillPaymentPayable = Loadable(
  lazy(() =>
    import("../views/Accounting/Bill/PaymentPayable/PaymentPayable").then(
      ({ AccountingBillPaymentPayable }) => ({
        default: AccountingBillPaymentPayable,
      })
    )
  )
);

const AccountingBankAccount = Loadable(
  lazy(() =>
    import("../views/Accounting/BankAccount/BankAccount").then(
      ({ AccountingBankAccount }) => ({
        default: AccountingBankAccount,
      })
    )
  )
);

const AccountingBankAccountList = Loadable(
  lazy(() =>
    import("../views/Accounting/BankAccount/BankAccountList").then(
      ({ AccountingBankAccountList }) => ({
        default: AccountingBankAccountList,
      })
    )
  )
);

const AccountingVendor = Loadable(
  lazy(() =>
    import("../views/Accounting/Vendor/Vendor").then(({ Vendor }) => ({
      default: Vendor,
    }))
  )
);

const AccountingVendorList = Loadable(
  lazy(() =>
    import("../views/Accounting/Vendor/VendorList").then(
      ({ AccountingVendorList }) => ({
        default: AccountingVendorList,
      })
    )
  )
);

const AccountingPaymentTabs = Loadable(
  lazy(() =>
    import("../views/Accounting/Payment/PaymentTab").then(
      ({ AccountingPaymentsTabs }) => ({
        default: AccountingPaymentsTabs,
      })
    )
  )
);

// const HabitationalAttachment = Loadable(
//   lazy(() =>
//     import(
//       "../views/customers/components/Policies/Habitational/HabitationalAttachments/HabitationalAttachments"
//     ).then(({ HabitationalAttachmnets }) => ({
//       default: HabitationalAttachmnets,
//     }))
//   )
// );

const HabitationalAddressHistory = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalAddressHistory/HabitationalAddressHistory"
    ).then(({ HabitationalAddressHistory }) => ({
      default: HabitationalAddressHistory,
    }))
  )
);

const HabitationalLiabilityExtensionExclusion = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalLiabilityExtension/HabitationalLiabilityExtension"
    ).then(({ HabitationalLiabilityExtensionExclusion }) => ({
      default: HabitationalLiabilityExtensionExclusion,
    }))
  )
);

const HabitationalDiscountSurcharges = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalDiscountSurcharges/HabitationalDiscountSurcharges"
    ).then(({ HabitationalDiscountSurcharges }) => ({
      default: HabitationalDiscountSurcharges,
    }))
  )
);

const HabitationalCoverages = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalCoverages/HabitationalCoverages"
    ).then(({ HabitationalCoverages }) => ({
      default: HabitationalCoverages,
    }))
  )
);

const HabitationalPremiumInformation = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalPremiumInformation/HabitationalPremiumInformation"
    ).then(({ HabitationalPremiumInformation }) => ({
      default: HabitationalPremiumInformation,
    }))
  )
);

const AllApplicationHr = Loadable(
  lazy(() =>
    import("../views/OnBoarding/AllApplication/AllApplication").then(
      ({ AllApplicationHR }) => ({
        default: AllApplicationHR,
      })
    )
  )
);

const LifeInsuranceComplianceCheckList = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/LifeInsurance/LifeInsurancePolicies/ComplieanceChecklist/ComplieanceChecklist"
    ).then(({ LifeInsuranceComplianceCheckList }) => ({
      default: LifeInsuranceComplianceCheckList,
    }))
  )
);

const LifeInsuranceTaskActivity = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/LifeInsurance/TaskActivity/TaskActivity"
    ).then(({ LifeFinancialTaskActivity }) => ({
      default: LifeFinancialTaskActivity,
    }))
  )
);

const LifeInsuranceFamilyTreeExistingList = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/LifeInsurance/LifeInsuranceAdditionalInformation/FamilyTreeExisting/FamilyTreeList"
    ).then(({ LifeInsuranceFamilyTreeList }) => ({
      default: LifeInsuranceFamilyTreeList,
    }))
  )
);

const LifeInsuranceFamilyTreeExisting = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/LifeInsurance/LifeInsuranceAdditionalInformation/FamilyTreeExisting/FamilyTreeExisting"
    ).then(({ LifeInsuranceFamilyTreeExisting }) => ({
      default: LifeInsuranceFamilyTreeExisting,
    }))
  )
);

//-----------Legal-------------
const LegalList = Loadable(
  lazy(() =>
    import("../views/legals/Legals").then(({ Legals }) => ({
      default: Legals,
    }))
  )
);

//-----------NOTICES-------------
const NoticesList = Loadable(
  lazy(() =>
    import("../views/notices/Notices").then(({ Notices }) => ({
      default: Notices,
    }))
  )
);

const PolicyNotesList = Loadable(
  lazy(() =>
    import("../views/customers/components/Policies/Notes/NotesList").then(
      ({ NotesList }) => ({
        default: NotesList,
      })
    )
  )
);

const BusinessPolicyNoteForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/BusinessPolicy/BusinessNoteForm/BusinessNoteForm"
    ).then(({ BusinessNoteForm }) => ({
      default: BusinessNoteForm,
    }))
  )
);
const CommAutoPolicyNoteForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/CommAutomobilePolicy/CommAutoNoteForm/CommAutoNoteForm"
    ).then(({ CommAutoNoteForm }) => ({
      default: CommAutoNoteForm,
    }))
  )
);
const AutomobilePolicyNoteForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/AutomobilePolicy/AutomobileNoteForm/AutomobileNoteForm"
    ).then(({ AutomobileNoteForm }) => ({
      default: AutomobileNoteForm,
    }))
  )
);
const HabitationalPolicyNoteForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/HabitationalNoteForm/HabitationalNoteForm"
    ).then(({ HabitationalNoteForm }) => ({
      default: HabitationalNoteForm,
    }))
  )
);

const HabitationalTaskActivity = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/Habitational/TaskActivity/TaskActivity"
    ).then(({ HabitationalTaskActivity }) => ({
      default: HabitationalTaskActivity,
    }))
  )
);

const LifeInsurancePolicyNoteForm = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/LifeInsurance/LifeInsuranceNoteForm/LifeInsuranceNoteForm"
    ).then(({ LifeInsuranceNoteForm }) => ({
      default: LifeInsuranceNoteForm,
    }))
  )
);

const LifeInsuranceHistory = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/LifeInsurance/LifeInsuranceBasicDetails/LifeInsuranceHistory/LifeInsuranceHistory"
    ).then(({ LifeInsuranceHistory }) => ({
      default: LifeInsuranceHistory,
    }))
  )
);
const LifeInsuranceAttachmentList = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/LifeInsurance/LifeInsuranceBasicDetails/Attachment/AttachmentList"
    ).then(({ LifeInsuranceAttachmentList }) => ({
      default: LifeInsuranceAttachmentList,
    }))
  )
);

const LifeInsuranceTask = Loadable(
  lazy(() =>
    import(
      "../views/customers/components/Policies/LifeInsurance/LifeInsuranceBasicDetails/LifeInsuranceTask/LifeInsuranceTask"
    ).then(({ LifeInsuranceTask }) => ({
      default: LifeInsuranceTask,
    }))
  )
);

const AppFormList = Loadable(
  lazy(() =>
    import("../views/appForm/AppFormList").then(({ AppFormList }) => ({
      default: AppFormList,
    }))
  )
);
const SupportTicketsList = Loadable(
  lazy(() =>
    import("../views/supportTickets/SupportTicketsList").then(
      ({ SupportTicketsList }) => ({
        default: SupportTicketsList,
      })
    )
  )
);
const SupportTickets = Loadable(
  lazy(() =>
    import("../views/supportTickets/SupportTickets").then(
      ({ SupportTickets }) => ({
        default: SupportTickets,
      })
    )
  )
);

const Utility = Loadable(
  lazy(() =>
    import("../views/settings/utility/Utility").then(({ Utility }) => ({
      default: Utility,
    }))
  )
);

const ManageUtility = Loadable(
  lazy(() =>
    import("../views/settings/utility/UtilitySideBar").then(
      ({ ManageUtility }) => ({
        default: ManageUtility,
      })
    )
  )
);

const EmailStatistics = Loadable(
  lazy(() =>
    import("../views/emailStatistics/EmailStatistics").then(
      ({ EmailStatistics }) => ({
        default: EmailStatistics,
      })
    )
  )
);
const Automation = Loadable(
  lazy(() =>
    import("../views/automation/Automation").then(({ Automation }) => ({
      default: Automation,
    }))
  )
);
const ManageAutomation = Loadable(
  lazy(() =>
    import("../views/automation/ManageAutomation").then(
      ({ ManageAutomation }) => ({
        default: ManageAutomation,
      })
    )
  )
);
const CSIOList = Loadable(
  lazy(() =>
    import("../views/csio/CSIO").then(({ CSIOLIst }) => ({
      default: CSIOList,
    }))
  )
);

const CSIOProgress= Loadable(
  lazy(() =>
    import("../views/csio/CSIOProgress").then(({ CSIOProgress }) => ({
      default: CSIOProgress,
    }))
  )
);

const ManageCSIO = Loadable(
  lazy(() =>
    import("../views/csio/ManageCSIO").then(({ ManageCSIO }) => ({
      default: ManageCSIO,
    }))
  )
);

const EquipmentList = Loadable(
  lazy(() =>
    import("../views/Equipment/EquipmentList").then(({ EquipmentList }) => ({
      default: EquipmentList,
    }))
  )
);

const ManageEquipment = Loadable(
  lazy(() =>
    import("../views/Equipment/ManageEquipment").then(
      ({ ManageEquipment }) => ({
        default: ManageEquipment,
      })
    )
  )
);

const CSIOEDOCSList = Loadable(
  lazy(() =>
    import("../views/csioEdocs/CSIODocsList").then(
      ({ CSIOEDocsList }) => ({
        default: CSIOEDocsList,
      })
    )
  )
);


const ManageCSIODocs = Loadable(
  lazy(() =>
    import("../views/csioEdocs/ManageCSIOEdocs").then(
      ({ ManageCSIOEdocs }) => ({
        default: ManageCSIOEdocs,
      })
    )
  )
);


/* ****Routes***** */

export const Router = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <FullLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        exact: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashboard",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[]} allowFullAccess>
            <Dashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/leads",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
            <Leads />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/leads/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
            <ManageLead />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/leads/manage/:leadId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
            <ManageLead />
          </AuthorizedRoute>
        ),
      },

      // ------------- Insurer Route ----------------------------

      {
        path: "/insurers",
        exact: true,
        element: (
          <AuthorizedRoute
            moduleIds={[
              MODULE_IDS.INSURER,
              MODULE_IDS.CSIO_COMPANY,
              MODULE_IDS.INSURER_SUB,
              MODULE_IDS.POLICY_COMISSION,
            ]}
          >
            <Insurers />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/insurers/:type",
        exact: true,
        element: (
          <AuthorizedRoute
            moduleIds={[
              MODULE_IDS.INSURER,
              MODULE_IDS.CSIO_COMPANY,
              MODULE_IDS.INSURER_SUB,
              MODULE_IDS.POLICY_COMISSION,
            ]}
          >
            <Insurers />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/insurer-composer",

        children: [
          {
            path: "/insurer-composer/",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.INSURER]}>
                <InsurerComposer />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/insurer-composer/:id",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.INSURER]}>
                <InsurerComposer />
              </AuthorizedRoute>
            ),
          },
        ],
      },

      // ------------- Sub Insurer Route ----------------------------
      {
        path: "/sub-insurer-composer",

        children: [
          {
            path: "/sub-insurer-composer/",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.INSURER_SUB]}>
                <SubInsurerComposer />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/sub-insurer-composer/:id",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.INSURER_SUB]}>
                <SubInsurerComposer />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/sub-insurer",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.INSURER_SUB]}>
            <SubInsurerList />
          </AuthorizedRoute>
        ),
      },

      //-------- Equipment

      {
        path: "/equipment-list",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <EquipmentList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/equipment/manage-equipment",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageEquipment />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/equipment/manage-equipment/:equipmentId",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageEquipment />
          </AuthorizedRoute>
        ),
      },

      //-------- CSIO EDOCS

      {
        path: "/csio-edocs-list",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            < CSIOEDOCSList/>
          </AuthorizedRoute>
        ),
      },

      {
        path: "/csio-edocs-list/manage-csio-edocs",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageCSIODocs />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/csio-edocs-list/manage-csio-edocs/:csioEdocsId",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            < ManageCSIODocs/>
          </AuthorizedRoute>
        ),
      },
      {
        path: "/csio-edocs-list/manage-csio-edoc/:messageGUID",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <CSIOEdocs/>
          </AuthorizedRoute>
        ),
      },

      // ------------- Underwriters Route ----------------------------
      {
        path: "/underwriter-composer",
        children: [
          {
            path: "/underwriter-composer/",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.UNDERWRITERS]}>
                <UnderWritersComposer />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/underwriter-composer/:id",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.UNDERWRITERS]}>
                <UnderWritersComposer />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/underwriters",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.UNDERWRITERS]}>
            <UnderWritersList />
          </AuthorizedRoute>
        ),
      },

      // ------------- Broker Code Route ----------------------------

      {
        path: "/broker-code-composer",
        children: [
          {
            path: "/broker-code-composer/",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BROKER_CODES]}>
                <BrokerCodeComposer />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/broker-code-composer/:id",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BROKER_CODES]}>
                <BrokerCodeComposer />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/broker-code",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.BROKER_CODES]}>
            <BrokerCodeList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/broker-contracts",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.BROKER_CONTACTS]}>
            <BrokerContactsList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/manage-broker-contracts",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.BROKER_CONTACTS]}>
            <ManageBrokerContacts />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/manage-broker-contracts/:brokerContactCode",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.BROKER_CONTACTS]}>
            <ManageBrokerContacts />
          </AuthorizedRoute>
        ),
      },

      // ------------- Branch Office Route ----------------------------

      {
        path: "/branch-office-composer",
        children: [
          {
            path: "/branch-office-composer/",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BRANCH_OFFICES]}>
                <BranchOfficeComposer />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/branch-office-composer/:id",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BRANCH_OFFICES]}>
                <BranchOfficeComposer />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/branch-office",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.BRANCH_OFFICES]}>
            <BranchOfficeList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/interested-party-composer",
        children: [
          {
            path: "/interested-party-composer",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.INTERESTED_PARTIES]}>
                <InterestedPartyComposer />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/interested-party-composer/:id",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.INTERESTED_PARTIES]}>
                <InterestedPartyComposer />
              </AuthorizedRoute>
            ),
          },
        ],
      },

      {
        path: "/interested-party",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.INTERESTED_PARTIES]}>
            <InterestedPartyList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/additional-interest-composer",
        children: [
          {
            path: "/additional-interest-composer",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ADDITIONAL_INTERESTS]}>
                <AdditionalInterestComposer />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/additional-interest-composer/:id",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ADDITIONAL_INTERESTS]}>
                <AdditionalInterestComposer />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/additional-interest",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ADDITIONAL_INTERESTS]}>
            <AdditionalInterestList />
          </AuthorizedRoute>
        ),
      },

      // ------------- Insurer Contact Route ----------------------------

      {
        path: "/insurer-contacts-composer",
        children: [
          {
            path: "/insurer-contacts-composer/",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.INSURER_CONTACTS]}>
                <InsurerContactsComposer />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/insurer-contacts-composer/:id",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.INSURER_CONTACTS]}>
                <InsurerContactsComposer />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/insurer-contacts",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.INSURER_CONTACTS]}>
            <InsurerContactsList />
          </AuthorizedRoute>
        ),
      },

      // ------------- CSIO Company ----------------------------

      {
        path: "/csio-company-composer",
        children: [
          {
            path: "/csio-company-composer/",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.CSIO_COMPANY]}>
                <CsioCompanyComposer />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/csio-company-composer/:id",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.CSIO_COMPANY]}>
                <CsioCompanyComposer />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/csio-company",
        exact: true,
        element: (
          <AuthorizedRoute
            allowFullAccess
            moduleIds={[MODULE_IDS.CSIO_COMPANY]}
          >
            <CsioCompanyList />
          </AuthorizedRoute>
        ),
      },

      // ------------- Policy Commission Company ----------------------------

      {
        path: "/policy-commission-composer",
        children: [
          {
            path: "/policy-commission-composer/",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.POLICY_COMISSION]}>
                <PolicyCommissionComposer />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/policy-commission-composer/:id",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.POLICY_COMISSION]}>
                <PolicyCommissionComposer />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/policy-commission",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.POLICY_COMISSION]}>
            <PolicyCommissionList />
          </AuthorizedRoute>
        ),
      },

      // ------------- opportunites ----------------------------
      {
        path: "/opportunites",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.OPPORTUNITY]}>
            <ViewOpportunites />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/opportunites/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.OPPORTUNITY]}>
            <CreateOpportunites />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/opportunites/manage/:oppId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.OPPORTUNITY]}>
            <CreateOpportunites />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/view-customers",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CUSTOMER]}>
            <CustomersList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/customer-overview/:id",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CUSTOMER]}>
            <CustomerOverview />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/policies",
        exact: true,
        element: (
          <AuthorizedRoute
            moduleIds={[
              MODULE_IDS.ALL_POLICY,
              MODULE_IDS.BUSINESS_POLICY,
              MODULE_IDS.COMMERCIAL_AUTO,
              MODULE_IDS.AUTOMOBILE,
              MODULE_IDS.HABITATIONAL,
              MODULE_IDS.LIFE_AND_FINCANCIAL,
            ]}
          >
            <PoliciesList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/policies/:line_of_business",
        exact: true,
        element: (
          <AuthorizedRoute
            moduleIds={[
              MODULE_IDS.ALL_POLICY,
              MODULE_IDS.BUSINESS_POLICY,
              MODULE_IDS.COMMERCIAL_AUTO,
              MODULE_IDS.AUTOMOBILE,
              MODULE_IDS.HABITATIONAL,
              MODULE_IDS.LIFE_AND_FINCANCIAL,
            ]}
          >
            <PoliciesList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/csio",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess={true} moduleIds={[]}>
            <CSIOLIst />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/csio-progress/:code",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess={true} moduleIds={[]}>
            <CSIOProgress />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/manage-csio-tab/",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess={true} moduleIds={[]}>
            <ManageCSIOTabs />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/manage-csio-tab/:id",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess={true} moduleIds={[]}>
            <ManageCSIOTabs />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-customer",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CUSTOMER]}>
            <CreateCustomer />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/edit-customer/:id",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CUSTOMER]}>
            <EditCustomer />
          </AuthorizedRoute>
        ),
      },

      /*------*/
      {
        path: "/business-policy/:customerId",
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
            <BusinessPolicyLayout />
          </AuthorizedRoute>
        ),
        children: [
          {
            path: "/business-policy/:customerId/basic-details/",
            exact: true,
            element: (
              <AuthorizedRoute
                moduleIds={[
                  MODULE_IDS.BUSINESS_POLICY,
                  MODULE_IDS.BUSINESS_POLICY_ADMIN_MANAGER,
                ]}
              >
                <BusinessPolicyBasicDetails />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/basic-details/:customerPolicyId",
            element: (
              <AuthorizedRoute
                moduleIds={[
                  MODULE_IDS.BUSINESS_POLICY,
                  MODULE_IDS.BUSINESS_POLICY_ADMIN_MANAGER,
                ]}
              >
                <BusinessPolicyBasicDetails />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/claims-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <BusinessClaim />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/csio-edocs-list/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <BusinessCSIOEDocsList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/coverage-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <CoverageRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/coverage-tab/:customerPolicyId/coverage",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Coverage />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/coverage-tab/:customerPolicyId/coverage/:coverageId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Coverage />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/coverage-tab/:customerPolicyId/risklocation",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <RiskLocation />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/coverage-tab/:customerPolicyId/risklocation/:riskLocationNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <RiskLocation />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/coverage-tab/:customerPolicyId/interested-party",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Interested />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/coverage-tab/:customerPolicyId/interested-party/:interestedNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Interested />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/coverage-tab/:customerPolicyId/additional",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Additional />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/coverage-tab/:customerPolicyId/additional/:additionalIntrestNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Additional />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/questionnare-tab/",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <QuestionnareRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/questionnare-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <QuestionnareRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/questionnare-tab/:customerPolicyId/resturant",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <ResturantQuestionare />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/questionnare-tab/:customerPolicyId/resturant/:resturantNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <ResturantQuestionare />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/questionnare-tab/:customerPolicyId/contractor",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <ContractorQuestionare />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/questionnare-tab/:customerPolicyId/contractor/:contractorNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <ContractorQuestionare />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/schedule-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <ScheduleRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/schedule-tab/:customerPolicyId/fine-art",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <FineArt />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/schedule-tab/:customerPolicyId/fine-art/:fineArtNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <FineArt />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/schedule-tab/:customerPolicyId/tool-floater",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <ToolFloater />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/schedule-tab/:customerPolicyId/tool-floater/:toolFloaterNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <ToolFloater />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/schedule-tab/:customerPolicyId/equipment",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Equipment />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/schedule-tab/:customerPolicyId/equipment/:equipmentNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Equipment />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/others-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <OthersRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/others-tab/:customerPolicyId/premium-finance",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Premium />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/others-tab/:customerPolicyId/premium-finance/:premiumNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Premium />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/schedule-tab/:customerPolicyId/underlying",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <UnderLying />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/schedule-tab/:customerPolicyId/underlying/:underlyingNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <UnderLying />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/schedule-tab/:customerPolicyId/subscription",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Subscription />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/schedule-tab/:customerPolicyId/subscription/:subscriptionNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Subscription />
              </AuthorizedRoute>
            ),
          },

          // {
          //   path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId",
          //   exact: true,
          //   element: (
          //     <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
          //       <PolicyFormsRoot />
          //     </AuthorizedRoute>
          //   ),
          // },

          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <PolicyFormsRoot />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/liability",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <CertificateLiabilityInsurance />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/liability/:liabilityNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <CertificateLiabilityInsurance />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-duplicate/:liabilityNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <CertificateLiabilityInsurance isDuplicate />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/property-insurance",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <CertificatePropertyInsurance />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/property-insurance/:liabilityNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <CertificatePropertyInsurance />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/property-insurance-duplicate/:liabilityNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <CertificatePropertyInsurance isDuplicate />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/garage-insurance",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <CertificateGarageInsuranceInsurance />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/garage-insurance/:garageNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <CertificateGarageInsuranceInsurance />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/garage-insurance-duplicate/:garageNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <CertificateGarageInsuranceInsurance isDuplicate />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/authorization",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Authorization />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/authorization/:authNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <Authorization />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/policy-forms-tab/:customerPolicyId/cancellation/:taskCode",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <BusinessCancellation />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/marketing-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <MarketingRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/marketing-tab/:customerPolicyId/marketing-history",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <AddMarketingHistory />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/marketing-tab/:customerPolicyId/marketing-history/:marketingHistNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <AddMarketingHistory />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/notes-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <BusinessNoteFormList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/notes-tab/:customerPolicyId/note",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <BusinessPolicyNoteForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/notes-tab/:customerPolicyId/note/:noteNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <BusinessPolicyNoteForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/attachments-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <BusinessAttachmentList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/history-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <BusinessActivityList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/history-tab/:customerPolicyId/history/:taskNumber",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <BusinessActivityList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/task-activity-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <BusinessTaskActivity />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/business-policy/:customerId/task-activity-tab/:customerPolicyId/tasks",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
                <CreateTasks fromPolicy policyType={POLICIES.BUSINESS_POLICY} />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/business-policy/:customerId/task-activity-tab/:customerPolicyId/tasks/:taskCode",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
                <CreateTasks fromPolicy policyType={POLICIES.BUSINESS_POLICY} />
              </AuthorizedRoute>
            ),
          },
        ],
      },

      // COMMERCIAL AUTO ROUTING---------------------------
      {
        path: "/comm-automobile-policy/:customerId",
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
            <CommAutomobilePolicyLayout />
          </AuthorizedRoute>
        ),
        children: [
          {
            path: "/comm-automobile-policy/:customerId/basic-details/",
            exact: true,
            element: (
              <AuthorizedRoute
                moduleIds={[
                  MODULE_IDS.COMMERCIAL_AUTO,
                  MODULE_IDS.COMM_AUTO_POLICY_ADMIN_MANAGER,
                ]}
              >
                <CommAutomobileBasicDetails />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/basic-details/:customerPolicyId",
            element: (
              <AuthorizedRoute
                moduleIds={[
                  MODULE_IDS.COMMERCIAL_AUTO,
                  MODULE_IDS.COMM_AUTO_POLICY_ADMIN_MANAGER,
                ]}
              >
                <CommAutomobileBasicDetails />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/comm-automobile-policy/:customerId/attachment-tab/:customerPolicyId/",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoDriverInformation />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/driver-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoDriverRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/driver-tab/:customerPolicyId/driver-information",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoDriverInformation />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/comm-automobile-policy/:customerId/driver-tab/:customerPolicyId/driver-information/:driverNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoDriverInformation />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/driver-tab/:customerPolicyId/claims",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoDriverClaims />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/driver-tab/:customerPolicyId/claims/:claimNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoDriverClaims />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/driver-tab/:customerPolicyId/conviction",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoDriverConviction />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/driver-tab/:customerPolicyId/conviction/:driverNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoDriverConviction />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/driver-tab/:customerPolicyId/activity-task",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoDriverActivityTask />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/driver-tab/:customerPolicyId/activity-task/:taskNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoDriverActivityTask />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoVehicleRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-tab/:customerPolicyId/vehicle-information",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileVehicle />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-tab/:customerPolicyId/vehicle-information/:vehicleNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileVehicle />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/garage-plate-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoGaragePlatesList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/garage-plate-tab/:customerPolicyId/garage-plate",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoGaragePlate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/garage-plate-tab/:customerPolicyId/garage-plate/:coverageId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoGaragePlate />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/comm-automobile-policy/:customerId/comm-vehicle-supplement-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoCSVRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/comm-vehicle-supplement-tab/:customerPolicyId/csv-detail-information",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoCSV />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/comm-vehicle-supplement-tab/:customerPolicyId/csv-detail-information/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoCSV />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoVehicleCoverageScheduleRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId/scheduleA",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileScheduleA />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId/scheduleA/:incrementalNumber/:vehicleNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileScheduleA />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId/scheduleB",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileScheduleB />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId/scheduleB/:driverNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileScheduleB />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId/scheduleC",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileScheduleC />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId/scheduleC/:driverNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileScheduleC />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId/scheduleD",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileScheduleD />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId/scheduleD/:driverNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileScheduleD />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/comm-automobile-policy/:customerId/application-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoApplicationRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/application-tab/:customerPolicyId/application",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileApplicationForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/application-tab/:customerPolicyId/application/:incrementalNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileApplicationForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/csio-edocs-list/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoCSIOEDocsList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/application-csio-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileCsioApplicationList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/application-csio-tab/:customerPolicyId/application/:messageGUID",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileCsioApplicationForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/application-tab/:customerPolicyId/garage",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileGarageApplicationOAF4 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/application-tab/:customerPolicyId/garage/:garageNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileGarageApplicationOAF4 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/marketing-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoMarketingRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/marketing-tab/:customerPolicyId/marketing-history",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileAddMarketing />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/marketing-tab/:customerPolicyId/marketing-history/:marketingHistNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileAddMarketing />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoPolicyFormsRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/authorization",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileAuthorization />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/authorization/:accountNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileAuthorization />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-slips",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileLiabilitySlips />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-slips/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileLiabilitySlips />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-slips-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileLiabilitySlips isDuplicate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-insurance",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoCSIOCertificate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-insurance/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoCSIOCertificate />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-insurance-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoCSIOCertificate isDuplicate />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-16",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileOPCF16 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-16/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileOPCF16 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-16-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileOPCF16 isDuplicate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/consent-form",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileConsentForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/consent-form/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileConsentForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/consent-form-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileConsentForm isDuplicate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/garage-form",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileGarageForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/garage-form/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileGarageForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/garage-form-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileGarageForm isDuplicate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/owner-form",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileOwnerForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/owner-form/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileOwnerForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/owner-form-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileOwnerForm isDuplicate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/sharing-fillable",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileSharingFillable />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/sharing-fillable/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileSharingFillable />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/sharing-fillable-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileSharingFillable isDuplicate />
              </AuthorizedRoute>
            ),
          },
          // {
          //   path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/supplementary-form",
          //   exact: true,
          //   element: (
          //     <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
          //       <CommAutomobileSupplementaryForm />
          //     </AuthorizedRoute>
          //   ),
          // },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/supplementary-form",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoSupplementaryFormTab />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/supplementary-form/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoSupplementaryFormTab />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/supplementary-form-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoSupplementaryFormTab isDuplicate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/retiree-discount",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileRetireeDiscount />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/retiree-discount/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileRetireeDiscount />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/retiree-discount-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileRetireeDiscount isDuplicate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/dwelling-declaration",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileDwellingDeclaration />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/dwelling-declaration/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileDwellingDeclaration />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/dwelling-declaration-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileDwellingDeclaration isDuplicate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/dwelling",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileDwelling />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/dwelling/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileDwelling />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/dwelling-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileDwelling isDuplicate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-17",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileOPCF17 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-17/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileOPCF17 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-17-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileOPCF17 isDuplicate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-28A",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoOPCF_28A />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-28A/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoOPCF_28A />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-28A-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoOPCF_28A isDuplicate />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/cancellation/:taskCode",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoCancellation />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/history-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoHistory />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/history-tab/:customerPolicyId/history/:taskNumber",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoHistory />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/attachments-tab/:customerPolicyId",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutomobileAttachmentList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/notes-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoNoteFormList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/notes-tab/:customerPolicyId/note",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoPolicyNoteForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/notes-tab/:customerPolicyId/note/:noteNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoPolicyNoteForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/task-activity-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.COMMERCIAL_AUTO]}>
                <CommAutoTaskActivity />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/comm-automobile-policy/:customerId/task-activity-tab/:customerPolicyId/tasks",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
                <CreateTasks
                  fromPolicy
                  policyType={POLICIES.COMMERCIAL_AUTO_POLICY}
                />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/comm-automobile-policy/:customerId/task-activity-tab/:customerPolicyId/tasks/:taskCode",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
                <CreateTasks
                  fromPolicy
                  policyType={POLICIES.COMMERCIAL_AUTO_POLICY}
                />
              </AuthorizedRoute>
            ),
          },
        ],
      },

      // AUTOMOBILE ROUTING---------------------------
      {
        path: "/automobile-policy/:customerId",
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
            <AutomobilePolicyLayout />
          </AuthorizedRoute>
        ),
        children: [
          {
            path: "/automobile-policy/:customerId/basic-details/",
            exact: true,
            element: (
              <AuthorizedRoute
                moduleIds={[
                  MODULE_IDS.COMMERCIAL_AUTO,
                  MODULE_IDS.AUTO_POLICY_ADMIN_MANAGER,
                ]}
              >
                <AutomobileBasicDetails />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/basic-details/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute
                moduleIds={[
                  MODULE_IDS.COMMERCIAL_AUTO,
                  MODULE_IDS.AUTO_POLICY_ADMIN_MANAGER,
                ]}
              >
                <AutomobileBasicDetails />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/driver-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutoDriverRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/driver-tab/:customerPolicyId/driver-information",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileDriverInformation />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/driver-tab/:customerPolicyId/driver-information/:driverNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileDriverInformation />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/driver-tab/:customerPolicyId/claims",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileDriverClaim />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/driver-tab/:customerPolicyId/claims/:claimNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileDriverClaim />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/driver-tab/:customerPolicyId/conviction",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileDriverConviction />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/driver-tab/:customerPolicyId/conviction/:driverNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileDriverConviction />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/driver-tab/:customerPolicyId/activity-task",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileDriverActivity />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/driver-tab/:customerPolicyId/activity-task/:taskNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileDriverActivity />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/vehicle-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutoVehicleRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/vehicle-tab/:customerPolicyId/vehicle-information",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileVehicle />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/vehicle-tab/:customerPolicyId/vehicle-information/:vehicleNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileVehicle />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileScheduleAList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId/manage-coverage",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileScheduleA />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/vehicle-coverage-schedule-tab/:customerPolicyId/manage-coverage/:vehicleNumber/:incrementalNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileScheduleA />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobilePolicyFormsRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/authorization",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileAuthorization />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/authorization/:authNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileAuthorization />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/cancellation/:taskCode",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileCancellation />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/automobile-csio-application-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileCSIOApplicationTab />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/automobile-csio-application-tab/:customerPolicyId/application",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileCSIOApplicationForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/automobile-csio-application-tab/:customerPolicyId/application/:messageGUID",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileCSIOApplicationForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/automobile-csio-application-tab/:customerPolicyId/policy-change/:messageGUID",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileCSIOApplicationForm />
              </AuthorizedRoute>
            ),
          },
          // {
          //   path: "/automobile-policy/:customerId/automobile-csio-application-tab/:customerPolicyId/policy-change-req",
          //   exact: true,
          //   element: (
          //     <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
          //       <AutomobileCSIOPolicyChangeReqForm />
          //     </AuthorizedRoute>
          //   ),
          // },
          // {
          //   path: "/automobile-policy/:customerId/automobile-csio-application-tab/:customerPolicyId/policy-change-req/:messageGUID",
          //   exact: true,
          //   element: (
          //     <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
          //       <AutomobileCSIOPolicyChangeReqForm />
          //     </AuthorizedRoute>
          //   ),
          // },
          {
            path: "/automobile-policy/:customerId/csio-edocs-list/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileCSIOEDocsList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-change-req/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobilePolicyChangeReqForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/automobile-application-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileApplicationList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/automobile-application-tab/:customerPolicyId/application",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileApplicationForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/automobile-application-tab/:customerPolicyId/application/:incrementalNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileApplicationForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/automobile-alberta-application-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileAlbertaApplicationList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/automobile-alberta-application-tab/:customerPolicyId/application",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileAlbertaApplicationForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-slips",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileLiabilitySLips />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-slips/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileLiabilitySLips />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-slips-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileLiabilitySLips />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-insurance",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileCSIOCertificat />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-insurance/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileCSIOCertificat />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/liability-insurance-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileCSIOCertificat />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-16",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileOPCF16 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-16/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileOPCF16 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-16-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileOPCF16 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-17",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileOPCF17 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-17/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileOPCF17 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-17-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileOPCF17 />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-28A",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileOPCF28A />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-28A/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileOPCF28A />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/policy-forms-tab/:customerPolicyId/opcf-28A-duplicate/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileOPCF28A />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/attachment-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileAttachmentList />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/automobile-policy/:customerId/notes-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileNoteFormList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/notes-tab/:customerPolicyId/note",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobilePolicyNoteForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/notes-tab/:customerPolicyId/note/:noteNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobilePolicyNoteForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/task-activity-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.AUTOMOBILE]}>
                <AutomobileTaskActivity />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/automobile-policy/:customerId/task-activity-tab/:customerPolicyId/tasks",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
                <CreateTasks
                  fromPolicy
                  policyType={POLICIES.AUTOMOBILE_POLICY}
                />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/automobile-policy/:customerId/task-activity-tab/:customerPolicyId/tasks/:taskCode",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
                <CreateTasks
                  fromPolicy
                  policyType={POLICIES.AUTOMOBILE_POLICY}
                />
              </AuthorizedRoute>
            ),
          },
        ],
      },

      // HABITATIONAL POLICY

      {
        path: "/habitational-policy/:customerId",
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
            <HabitationalPolicyLayout />
          </AuthorizedRoute>
        ),
        children: [
          {
            path: "/habitational-policy/:customerId/basic-details/",
            exact: true,
            element: (
              <AuthorizedRoute
                moduleIds={[
                  MODULE_IDS.HABITATIONAL,
                  MODULE_IDS.HABITATIONAL_POLICY_ADMIN_MANAGER,
                ]}
              >
                <HabitationalBasicDetails />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/basic-details/:customerPolicyId",
            element: (
              <AuthorizedRoute
                moduleIds={[
                  MODULE_IDS.HABITATIONAL,
                  MODULE_IDS.HABITATIONAL_POLICY_ADMIN_MANAGER,
                ]}
              >
                <HabitationalBasicDetails />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/claims-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalClaim />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/claims-tab/:customerPolicyId",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalClaim />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/risk-location-tab/:customerPolicyId",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <RiskLocationRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/risk-location-tab/:customerPolicyId/interested-party",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <HabitationalInterested />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/risk-location-tab/:customerPolicyId/interested-party/:interestedNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.BUSINESS_POLICY]}>
                <HabitationalInterested />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/risk-location/:customerPolicyId/application",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalRiskLocation/>
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/risk-location/:customerPolicyId/application/:incrementalNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalRiskLocation/>
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/coverage-tab/:customerPolicyId",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalCoveragesList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/habitational-coverages/:customerPolicyId",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalCoverages />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/habitational-coverages/:customerPolicyId/:interestedNumber",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalCoverages />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/activity-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalCreateTask />
              </AuthorizedRoute>
            ),
          },
          //below this route need to correct
          {
            path: "/habitational-policy/:customerId/activity-tab/:customerPolicyId/task/:taskNumber",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalCreateTask />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/history-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalHistory />
              </AuthorizedRoute>
            ),
          },
          //below this route need to correct
          {
            path: "/habitational-policy/:customerId/history-tab/:customerPolicyId/history/:taskNumber",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalHistory />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/attachments-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalAttachmentList />
              </AuthorizedRoute>
            ),
          },
          //below this route need to correct
          // {
          //   path: "/habitational-policy/:customerId/attachments-tab/:customerPolicyId/history/:taskNumber",
          //   element: (
          //     <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
          //       <HabitationalAttachmentList />
          //     </AuthorizedRoute>
          //   ),
          // },

          {
            path: "/habitational-policy/:customerId/notes-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalNoteFormList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/notes-tab/:customerPolicyId/note",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalPolicyNoteForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/notes-tab/:customerPolicyId/note/:noteNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalPolicyNoteForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/task-activity-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalTaskActivity />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/task-activity-tab/:customerPolicyId/tasks",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
                <CreateTasks
                  fromPolicy
                  policyType={POLICIES.HABITATIONAL_POLICY}
                />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/habitational-policy/:customerId/task-activity-tab/:customerPolicyId/tasks/:taskCode",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
                <CreateTasks
                  fromPolicy
                  policyType={POLICIES.HABITATIONAL_POLICY}
                />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/application-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalApplicationList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/application-tab/:customerPolicyId/application",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalApplicationTabs />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/csio-edocs-list/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalCSIOEDocsList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/habitational-application-csio-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalCSIOApplicationTab />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/habitational-application-csio-tab/:customerPolicyId/application",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalCSIOApplicationTabs />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/habitational-application-csio-tab/:customerPolicyId/application/:messageGUID",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalCSIOApplicationTabs />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/policy-change-request/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalPolicyChangeReqForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/habitational-application-csio-tab/:customerPolicyId/policy-change/:messageGUID",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalCSIOApplicationTabs />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/application-tab/:customerPolicyId/application/:IncrementalNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalApplicationTabs />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/binder-csio-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalBinderRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/binder-csio-tab/:customerPolicyId/binder",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalBinderCsio />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/binder-csio-tab/:customerPolicyId/binder/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalBinderCsio />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/binder-csio-tab/:customerPolicyId/non-binder",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalBinderNonCsio />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/binder-csio-tab/:customerPolicyId/non-binder/:serialNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalBinderNonCsio />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/policy-forms-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalPolicyFormsRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/policy-forms-tab/:customerPolicyId/payment",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalPaymentAUthortization />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/policy-forms-tab/:customerPolicyId/payment/:authNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalPaymentAUthortization />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/habitational-policy/:customerId/policy-forms-tab/:customerPolicyId/cancellation/:taskCode",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.HABITATIONAL]}>
                <HabitationalCancellation />
              </AuthorizedRoute>
            ),
          },
        ],
      },

      //----------------------------LIFE INSURANCE POLICY ------------------------------
      {
        path: "/life-insurance-policy/:customerId",
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
            <LifeInsurancePolicyLayout />
          </AuthorizedRoute>
        ),
        children: [
          {
            path: "/life-insurance-policy/:customerId/basic-details/",
            exact: true,
            element: (
              <AuthorizedRoute
                moduleIds={[
                  MODULE_IDS.LIFE_AND_FINCANCIAL,
                  MODULE_IDS.LIFE_AND_FINCANCIAL_POLICY_ADMIN_MANAGER,
                ]}
              >
                <LifeInsuranceBasicDetails />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/basic-details/:customerPolicyId",
            element: (
              <AuthorizedRoute
                moduleIds={[
                  MODULE_IDS.LIFE_AND_FINCANCIAL,
                  MODULE_IDS.LIFE_AND_FINCANCIAL_POLICY_ADMIN_MANAGER,
                ]}
              >
                <LifeInsuranceBasicDetails />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/family-tree/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceFamilyTreeExistingList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/family-tree/:customerPolicyId/family",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceFamilyTreeExisting />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/family-tree/:customerPolicyId/family/:familyNo",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceFamilyTreeExisting />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/coverage-needs-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceCoverageNeed />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/history-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceHistory />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/attachment-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceAttachmentList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/activity-task-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceTask />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/compliance-checklist-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceComplianceCheckList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/additional-information-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceAdditionalInformationRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/claims-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsurerClaim />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/task-activity-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceTaskActivity />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/task-activity-tab/:customerPolicyId/tasks",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
                <CreateTasks
                  fromPolicy
                  policyType={POLICIES.LIFE_AND_FINCANCIAL_POLICY}
                />
              </AuthorizedRoute>
            ),
          },

          {
            path: "/life-insurance-policy/:customerId/task-activity-tab/:customerPolicyId/tasks/:taskCode",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
                <CreateTasks
                  fromPolicy
                  policyType={POLICIES.LIFE_AND_FINCANCIAL_POLICY}
                />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/notes-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceNoteFormList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/notes-tab/:customerPolicyId/note",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsurancePolicyNoteForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/notes-tab/:customerPolicyId/note/:noteNumber",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsurancePolicyNoteForm />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/policy-forms-tab/:customerPolicyId",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsurancePolicyFormsRoot />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/life-insurance-policy/:customerId/policy-forms-tab/:customerPolicyId/cancellation/:taskCode",
            exact: true,
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.LIFE_AND_FINCANCIAL]}>
                <LifeInsuranceCancellation />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/tasks",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
            <CreateTasks />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/tasks/:taskCode",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
            <CreateTasks />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <BeforeProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/staff-directory",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.STAFF_USER]}>
            <StaffDirectory />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/users/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <ManageUser />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users/manage/:userId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <ManageUser />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users/view-profile/:userId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <ViewProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/my-profile",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[]} allowFullAccess>
            <MyProfileView />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/task-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
            <BeforeCreate />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/old-task-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[]} allowFullAccess>
            <OldBusinessTracker />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/task-view/:taskCode",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
            <ViewTaskDetails />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/insurance-reports",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.INSURANCE_REPORTS]}>
            <InsuraceReportsTabs />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/old-insurance-reports",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[]} allowFullAccess>
            <OldInsuranceReportsList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/insurance-reports/order-insurance-report",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.INSURANCE_REPORTS]}>
            <OrderInsuranceReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/insurance-reports/no-policy/:licenseNumber/:incrementalNumber",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.INSURANCE_REPORTS]}>
            <NoPolicyInsuranceReports />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/taskboard",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_TASKBOARD]}>
            <TaskBoard />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/task-calendar",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_TASK]}>
            <TaskCalender />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/email",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.NONE]} allowFullAccess>
            <Email />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/templates",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TEMPLATES]}>
            <TemplatesList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/templates/manage-template",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TEMPLATES]}>
            <MessagingTemplate />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/templates/manage-template/:templateCode",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TEMPLATES]}>
            <MessagingTemplate />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/all-applications",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.HR_APPLICATION]}>
            <AllApplicationHr />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/list-security-groups",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ROLE]}>
            <ListSecurityGroups />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/create-security-group",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ROLE_MODULE]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/create-security-group/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ROLE_MODULE]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/secuirty-role-duplicate/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ROLE_MODULE]}>
            <SecurityDashboard isDuplicate />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/on-boarding",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.HR_ONBOARDING]}>
            <OnBoardingList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/on-boarding/:applicationNumber",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.HR_ONBOARDING]}>
            <OnBoardingList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/on-boardings",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.HR_ONBOARDING]}>
            <OnBoardingList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-offboarding",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.HR_OFFBOARDING]}>
            <OffBoarding />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/request-forms",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.HR_REQUEST_FORMS]}>
            <RequestFormsList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/legals",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.LEGAL]}>
            <LegalList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/notices",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_NOTICES]}>
            <NoticesList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/view-insurer",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.INSURER]}>
            <ViewInsurerReport />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/view-insurer-contact",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.INSURER_CONTACTS]}>
            <ViewInsurerContactList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/renewals",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_RENEWALS]}>
            <RenewalList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/binders",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_BINDERS]}>
            <TaskBinderList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/all-binders",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ALL_BINDERS]}>
            <BinderList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/marketing",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <TaskMarketingsList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/company",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingCompany />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/company/:companyId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingCompany />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/chart-account-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <ChartOfAccountList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/chart-account",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <ChartOfAccount />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/chart-account/:companyChartsOfAccCode",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <ChartOfAccount />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/recievable",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingRecievableTabs />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-recievable",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingRecievable />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/trust",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingRecievableTrust />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/trust/:incrementalNumber",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingRecievableTrust />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-eft",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingEft />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-account-payable",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingAccountPayable />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/cheque",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingCheque />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/cheque/:paymentId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingCheque />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-interac-email-transfer",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingInteracEmailTransfer />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-payment",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingPayment />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-payment/:invoiceId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingPayment />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/company-detail",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingCompanyDetail />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/company-onboarding",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingCompanyOnboarding />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/company-onboarding/:companyId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingCompanyOnboarding />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/tax-info",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingTaxInfo />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/transaction-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingTransactionList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/transaction/create",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingTransactions />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/transaction-type-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingTransactionTypesList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-transaction-type",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <InvoiceTransactionType />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-transaction-type/:transactionTypeId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <InvoiceTransactionType />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/transaction-description-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingTransactionDescriptionList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-transaction-description",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <InvoiceTransactionDescription />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-transaction-description/:transactionDescriptionId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <InvoiceTransactionDescription />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/information-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingInformationList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/information/create",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingInformation />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/invoice-payment-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingPaymentList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/cheque-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingChequeList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/invoice-template",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <InvoiceTemplate />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/invoice-template/:customerPolicyId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <InvoiceTemplate />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/bill-template",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <BillTemplate />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/bill-template/:customerPolicyId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <BillTemplate />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/invoicing-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingInvoiceInformationList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/invoicing/create",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingInvoiceInformation />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/invoicing/create/:invoiceId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingInvoiceInformation />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/bill-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingBillList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/bill/create",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingBill />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/bill/create/:billId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingBill />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/bill-payment-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingBillPaymentPayableList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/bill/payment/create",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingBillPaymentPayable />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/bill/payment/create/:paymentId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingBillPaymentPayable />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/bank-account-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingBankAccountList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/bank-account/",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingBankAccount />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/bank-account/:banAccCode",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingBankAccount />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/payment",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
            <AccountingPaymentTabs />
          </AuthorizedRoute>
        ),
      },

      // {
      //   path: "/recievable-trust",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
      //       <AccountingRecievableTrust />
      //     </AuthorizedRoute>
      //   ),
      // },

      {
        path: "/vendor",
        exact: true,
        children: [
          {
            path: "/vendor/list",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
                <AccountingVendorList />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/vendor/create",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
                <AccountingVendor />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/vendor/create/:vendorCode",
            element: (
              <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_MARKETING]}>
                <AccountingVendor />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/app-form",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <AppFormList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/support-tickets",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <SupportTicketsList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/manage-support-tickets",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <SupportTickets />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/manage-support-tickets/:supportTicketsCode",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <SupportTickets />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/notes-list",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <TaskNotesList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/manage-note",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageTaskNote />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/manage-note/:customerPolicyId/:noteId",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageTaskNote />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/g4sign/documents",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.G4_SIGN]}>
            <Documents />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/g4sign/documents/manage",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.G4_SIGN]}>
            <UpsertDocument />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/g4sign/documents/manage/:documentCode",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.G4_SIGN]}>
            <UpsertDocument />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/settings/utilities",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <Utility />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/settings/utilities/manage-utility",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageUtility />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/settings/automation",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <Automation />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/settings/automation/manage-automation",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageAutomation />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/settings/automation/manage-automation/:automationCode",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <ManageAutomation />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/email-statistics",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <EmailStatistics />
          </AuthorizedRoute>
        ),
      },

      { path: "access", element: <AccessDenied /> },
      { path: "error", element: <OOPSError /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },

  // --------------------------------TASK ROUTING----------------------------------------------

  {
    path: "/auth",
    element: (
      <PublicRoute>
        <BlankLayout />
      </PublicRoute>
    ),
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "reset-password", element: <ForgotPassword /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];
