import { Moment } from "moment";
import { LoadState } from "../../../constants/enums";

export interface IHabitationalClaimState {
  data: IhabitationalClaims[];
  loading: LoadState;
}

export interface IhabitationalClaims {
  customer_policy_id: number;
  claim_number: string | null;
  date_of_loss: string | null;
  time_of_loss: string;
  value_of_loss: string | null;
  location: string | null;
  amount_paid_dollar: string | null;
  loss_details: string | null;
  ours: string | null;
  policy_number: string | null;
  insurance_company: string | null;
  cause_of_loss_and_description: null;
  witness: string | null;
  witness_phone: string | null;
  witness_name: string | null;
  loss_reported_to_police: string | null;
  report_number: string | null;
  adjuster: string | null;
  phone: string | null;
  phone_ext: string | null;
  mobile: string | null;
  email: string | null;
  company_name: string | null;
  at_fault: string | null;
  claim_status: string | null;
  third_party_liability: string | null;
  bodily_injury: string | null;
  accidents_benefits: string | null;
  dcpd: string | null;
  collision: string | null;
  comprehensive: string | null;
  reserves: string | null;
  cgl: string | null;
  stock: string | null;
  crime: string | null;
  equipment_breakdown: string | null;
  business_interruption: string | null;
  other: string | null;
  total_claim: string | null;
  contact_name: string | null;
  contact_phone: string | null;
  contact_phone_ext: string | null;
  contact_mobile: string | null;
  contact_email: string | null;
  preferred_contact_method: string | null;
  status: string | null;
}

export const initialHabitationalClaimDetails: IHabitationalClaimState = {
  data: [],
  loading: LoadState.NotLoaded,
};
