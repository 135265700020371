import React from "react";
import { Checkbox, Grid, Stack, Typography } from "@mui/material";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../../../../../components/formsComponents/CustomDatePicker";
import { PhoneTextField } from "../../../../../../components/PhoneTextField/PhoneTextField";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { MODULE_IDS } from "../../../../../../constants/enums";
import { IAutomobileCSIOPolicyState } from "../../../../../../redux/automobileCSIOPolicyChangeReq/automobileCSIOPolicyChangeReq.types";
import { StandadCard } from "../../../../../../components/Cards";

export const AutomobileCSIOPolicyChangeReqStep1: React.FC<{
  values: IAutomobileCSIOPolicyState;
  handleChange: any;
  setValues: any;
  setFieldValue: any;
  changeTab: (value: number) => void;
}> = (props) => {
  const { values, setValues, handleChange, setFieldValue } = props;

  const handleBrokerAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      broker_agent_address: data.address,
      broker_agent_city: data.city,
      broker_agent_country: data.country,
      broker_agent_state: data.state,
      broker_agent_zip: data.postalCode,
    });
  };
  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      applicant_details: {
        address: data.address,
        city: data.city,
        country: data.country,
        province_or_state: data.state,
        postalcode: data.postalCode,
      },
    });
  };
  return (
    <StandadCard>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Insurance Company</CustomFormLabel>
          <CustomTextField
            name="insurance_company"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.insurance_company}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Policy/Binder Number</CustomFormLabel>
          <CustomTextField
            name="policy_number"
            variant="outlined"
            size="small"
            type="text"
            value={values.policy_number}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Policy type</CustomFormLabel>
          <CustomTextField
            name="policy_type"
            variant="outlined"
            size="small"
            type="text"
            value={values.policy_type}
            fullWidth
            onChange={handleChange}
          />
        </Grid>

        {/* <Grid item xs={12} lg={2}>
          <CustomFormLabel>Language Preferred</CustomFormLabel>
          <Stack direction={"row"} alignItems={"center"}>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography>English</Typography>
              <Checkbox
                checked={values.language_preferred === "EN"}
                onChange={(e) => {
                  setFieldValue("language_preferred", "English");
                }}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography>French</Typography>
              <Checkbox
                checked={values.language_preferred === "FN"}
                onChange={(e) => {
                  setFieldValue("language_preferred", "French");
                }}
              />
            </Stack>
          </Stack>
        </Grid> */}
      </Grid>

      <Typography variant="h3" sx={{ mt: 3 }} fontWeight={600}>
        Insured Details
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>Customer Name</CustomFormLabel>
          <CustomTextField
            name="applicant_details.name"
            value={values.applicant_details.name}
            fullWidth
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={5}>
          <CustomFormLabel>Address</CustomFormLabel>
          <LocationAutoComplete
            id="applicant_details.address"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.address}
            onLocationChange={handleAddress}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Address 2</CustomFormLabel>
          <CustomTextField
            name="applicant_details.address_two"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.address_two}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Country</CustomFormLabel>
          <CustomTextField
            name="applicant_details.country"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.country}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <CustomFormLabel>City</CustomFormLabel>
          <CustomTextField
            name="applicant_details.city"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <CustomFormLabel>State</CustomFormLabel>
          <CustomTextField
            name="applicant_details.province_or_state"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.province_or_state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <CustomFormLabel>Postal Code</CustomFormLabel>
          <CustomTextField
            name="applicant_details.postalcode"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.postalcode}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Contact Number</CustomFormLabel>
          <CustomTextField
            name="applicant_details.contact_number"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.contact_number}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Home</CustomFormLabel>
          <CustomTextField
            name="applicant_details.home"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.home}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <CustomFormLabel>Cell</CustomFormLabel>
          <CustomTextField
            name="applicant_details.cell"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.cell}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Business</CustomFormLabel>
          <CustomTextField
            name="applicant_details.business"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.business}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Fax</CustomFormLabel>
          <CustomTextField
            name="applicant_details.fax"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.fax}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Email</CustomFormLabel>
          <CustomTextField
            name="applicant_details.email"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Website</CustomFormLabel>
          <CustomTextField
            name="applicant_details.website"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.applicant_details.website}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Typography variant="h3" sx={{ mt: 3 }} fontWeight={600}>
        Broker
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Broker Agent</CustomFormLabel>
          <CustomTextField
            name="broker.broker_agent"
            value={values.broker.broker_agent}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Address</CustomFormLabel>

          <LocationAutoComplete
            id="broker.broker_agent_address"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.broker.broker_agent_address}
            onLocationChange={handleBrokerAddress}
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Unit/Suite</CustomFormLabel>
          <CustomTextField
            name="broker.broker_agent_unit_or_suite"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.broker.broker_agent_unit_or_suite}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <CustomFormLabel>City</CustomFormLabel>
          <CustomTextField
            name="broker.broker_agent_city"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.broker.broker_agent_city}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Province/State</CustomFormLabel>
          <CustomTextField
            name="broker.broker_agent_state"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.broker.broker_agent_state}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Country</CustomFormLabel>
          <CustomTextField
            name="broker.broker_agent_country"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.broker.broker_agent_country}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Postal/ZIP Code</CustomFormLabel>
          <CustomTextField
            name="broker.broker_agent_zip"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.broker.broker_agent_zip}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Broker Code</CustomFormLabel>
          <CustomTextField
            name="broker.broker_code"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.broker.broker_code}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Broker Subcode</CustomFormLabel>
          <CustomTextField
            name="broker.broker_subcode"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.broker.broker_subcode}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Typography variant="h3" sx={{ mt: 3 }} fontWeight={600}>
        Policy Peroid
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Effective Date</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.policy_period_effective_date || ""}
            onChange={(newValue) => {
              setFieldValue("policy_period_effective_date", newValue);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Effective Time</CustomFormLabel>
          <CustomTimePicker
            fullWidth
            value={values.policy_period_effective_time}
            onChange={(newValue) => {
              setFieldValue(
                "policy_period_effective_time",
                newValue.toString()
              );
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Expiry Date</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.policy_period_expiry_date || ""}
            onChange={(newValue) => {
              setFieldValue("policy_period_expiry_date", newValue);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Expiry Time</CustomFormLabel>
          <CustomTimePicker
            fullWidth
            value={values.policy_period_expiry_time}
            onChange={(newValue) => {
              setFieldValue("policy_period_expiry_time", newValue);
            }}
          />
        </Grid>
      </Grid>

      <Typography variant="h3" sx={{ mt: 3 }} fontWeight={600}>
        Name / Address Change
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <CustomFormLabel>
            Name Changed (Refer to Section 1) GIVE REASON FOR CHANGE{" "}
          </CustomFormLabel>
          <CustomTextField
            name="name_changed"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.name_changed}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <CustomFormLabel>
            Address Changed (Refer to Section 1){" "}
          </CustomFormLabel>
          <CustomTextField
            name="address_changed"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.address_changed}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Stack direction={"row"} spacing={2} marginTop={3}>
        <RoleBasedCustomButton
          moduleId={MODULE_IDS.AUTOMOBILE}
          variant="contained"
          onClick={() => props.changeTab(1)}
        >
          Next
        </RoleBasedCustomButton>
      </Stack>
    </StandadCard>
  );
};
