/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  SelectChangeEvent,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import { TabPanel } from "../../../../../../components/Tabs/TabPanel";

import { TabsList } from "../../../../../../components/Tabs/TabsList";
import {
  AccountCircle,
  AdminPanelSettings,
  Receipt,
  StickyNote2,
} from "@mui/icons-material";
import {
  clearAutomobileCSIOApplicationPolicyReqState,
  fetchAutomobileCSIOApplicationPolicyReqAsync,
} from "../../../../../../redux/automobileCSIOPolicyChangeReq/automobileCSIOPolicyChangeReqActions";
import { HabitationalPolicyChangeReqStep1 } from "./HabitationalPolicyChangeReqStep1";
import {
  clearHabitationalPolicyChangeReqState,
  fetchHabitationalPolicyChangeReqAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalPolicyChangeRequest/habitationalPolicyChangeReqActions";
import { HabitationalPolicyChangeReqStep2 } from "./HabitationalPolicyChangeReqStep2";
import { HabitationalPolicyChangeReqStep3 } from "./HabitationalPolicyChangeReqStep3";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import {
  IHabitationalLossPayee,
  IHabitationalRiskAddress,
} from "../../../../../../redux/habitationalPolicy/habitationalPolicyChangeRequest/habitationalPolicyChangeReq.types";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import produce from "immer";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../../../../../components/formsComponents/CustomDatePicker";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { LabelCard } from "../../../../../../components/LabelCard/LabelCard";
import { CurrencyTextField } from "../../../../../../components/CurrencyTextField/CurrencyTextField";
import {
  apparatus,
  distance_to_hydrant,
  distance_to_reponding_fire_hall,
  fire_burglar_smoke,
  fuel,
  hibatational_location,
  hydrant_type,
  pool_type,
  smoke_detector_type,
  structure_type,
  swimming_pool_type,
} from "../../../../../../constants/constants";
export const HabitationalPolicyChangeReqForm: React.FC = () => {
  const { customerId, customerPolicyId, incrementalNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    incrementalNumber?: string;
  };

  const { policyChangeData, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.policyChangeReq
  );

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: policyChangeData,
    validate: (values) => {},
    onSubmit: (values) => {},
  });


  React.useEffect(() => {
    if (incrementalNumber) {
      dispatch(fetchHabitationalPolicyChangeReqAsync(incrementalNumber));
    }
  }, [incrementalNumber]);

  React.useEffect(() => {
    setValues(policyChangeData);
  }, [policyChangeData]);

  React.useEffect(() => {
    return () => {
      dispatch(clearHabitationalPolicyChangeReqState());
    };
  }, []);

  const handleBrokerAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      broker: {
        ...values.broker,
        broker_agent_address: data.address,
        broker_agent_city: data.city,
        broker_agent_country: data.country,
        broker_agent_state: data.state,
        broker_agent_zip: data.postalCode,
      },
    });
  };
  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      applicant_details: {
        ...values.applicant_details,
        address: data.address,
        city: data.city,
        country: data.country,
        province_or_state: data.state,
        postalcode: data.postalCode,
      },
    });
  };

  const updateRiskAddress = (key: string, rowIndex: number, value: any) => {
    const newValues = produce(values.risk_address, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("risk_address", newValues);
  };

  const handleChangeRiskAddress =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateRiskAddress(key, rowIndex, e.target.value);
    };

  const handleSelectRiskAddress =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateRiskAddress(key, rowIndex, e.target.value);
    };
  const updateAttachments = (key: string, rowIndex: number, value: any) => {
    const newValues = produce(values.attachments_type, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("attachments_type", newValues);
  };

  const handleChangeAttachments =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateAttachments(key, rowIndex, e.target.value);
    };

  const handleSelectAttachments =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateRiskAddress(key, rowIndex, e.target.value);
    };

  const handleHeatingType =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(values.heating_type, (draftRows: any) => {
        draftRows[rowIndex][key] = event.target.value;
      });
      setValues({ ...values, heating_type: finalRows });
    };
  const handleRenovationUpgrade =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(values.renovation_upgrade, (draftRows: any) => {
        draftRows[rowIndex][key] = event.target.value;
      });
      setValues({ ...values, renovation_upgrade: finalRows });
    };

  const handleAdditionalCoveragesChange =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(
        values.additional_coverage,
        (draftRows: any) => {
          draftRows[rowIndex][key] = event.target.value;
        }
      );
      setValues({ ...values, additional_coverage: finalRows });
    };
  const handleLiabilityExtentionChange =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(
        values.liability_extensions,
        (draftRows: any) => {
          draftRows[rowIndex][key] = event.target.value;
        }
      );
      setValues({ ...values, liability_extensions: finalRows });
    };

  const handleDiscountSurchargeChange =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(
        values.discount_and_surcharges,
        (draftRows: any) => {
          draftRows[rowIndex][key] = event.target.value;
        }
      );
      setValues({ ...values, discount_and_surcharges: finalRows });
    };

  const handleSchedulePersonalChange =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(values.schedule_personal, (draftRows: any) => {
        draftRows[rowIndex][key] = event.target.value;
      });
      setValues({ ...values, schedule_personal: finalRows });
    };

  const handleWaterCraftTrailerOne =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(
        values.watercraft_and_trailer_one,
        (draftRows: any) => {
          draftRows[rowIndex][key] = event.target.value;
        }
      );
      setValues({ ...values, watercraft_and_trailer_one: finalRows });
    };
  const handleWaterCraftTrailerTwo =
    (rowIndex: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const finalRows = produce(
        values.watercraft_and_trailer_one,
        (draftRows: any) => {
          draftRows[rowIndex][key] = event.target.value;
        }
      );
      setValues({ ...values, watercraft_and_trailer_one: finalRows });
    };

  const handleCoverageFormCurrencyChange =
    (rowIndex: number, key: string) => (value: string) => {
      const newValues = produce(
        values.coverag_forms.coverag_form_table,
        (draftValues) => {
          //@ts-ignore
          draftValues[rowIndex][key] = value;
        }
      );
      setFieldValue("coverag_forms.coverag_form_table", newValues);
    };

  const describedRiskAddress: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 5,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "loc_no",
        headerName: "Loc #",
        fieldName: "loc_no",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalRiskAddress) => {
          return (
            <CustomTextField
              fullWidth
              value={row.loc_no}
              onChange={handleChangeRiskAddress(
                "loc_no",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "address",
        headerName: "Address",
        fieldName: "address",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalRiskAddress) => {
          return (
            <CustomTextField
              fullWidth
              value={row.address}
              onChange={handleChangeRiskAddress(
                "address",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.risk_address,
  };
  const updateLossPayee = (key: string, rowIndex: number, value: any) => {
    const newValues = produce(values.loss_payee, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("loss_payee", newValues);
  };

  const handleChangeLossPayee =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateLossPayee(key, rowIndex, e.target.value);
    };

  const handleSelectLossPayee =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateLossPayee(key, rowIndex, e.target.value);
    };

  const describedLossPayee: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 5,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "loc_no",
        headerName: "Loc #",
        fieldName: "loc_no",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalLossPayee) => {
          return (
            <CustomTextField
              fullWidth
              value={row.loc_no}
              onChange={handleChangeLossPayee("loc_no", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "address",
        headerName: "Address",
        fieldName: "address",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalLossPayee) => {
          return (
            <CustomTextField
              fullWidth
              value={row.address}
              onChange={handleChangeLossPayee("address", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "rank",
        headerName: "Rank",
        fieldName: "rank",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalLossPayee) => {
          return (
            <CustomTextField
              fullWidth
              value={row.rank}
              onChange={handleChangeLossPayee("rank", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "nature_of_interest",
        headerName: "Nature of Interest",
        fieldName: "nature_of_interest",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalLossPayee) => {
          return (
            <CustomTextField
              fullWidth
              value={row.nature_of_interest}
              onChange={handleChangeLossPayee(
                "nature_of_interest",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.loss_payee,
  };

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <StandadCard sx={{ mt: 2 }} heading="Habitational Policy Change Req Form">
        {/* <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={tab}
          tabs={[
            { label: "Step 1", icon: <AccountCircle /> },
            {
              label: "Step 2",
              icon: <AdminPanelSettings />,
              //   disabled: !savedCustomerPolicyId,
            },
            {
              label: "Step 3",
              icon: <StickyNote2 />,
              //   disabled: !savedCustomerPolicyId,
            },
            {
              label: "Step 4",
              icon: <StickyNote2 />,
              //   disabled: !savedCustomerPolicyId,
            },
          ]}
          onChange={handleStepChange}
        /> */}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Insurance Company</CustomFormLabel>
              <CustomTextField
                name="insurance_company"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.insurance_company}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Policy/Binder Number</CustomFormLabel>
              <CustomTextField
                name="policy_number"
                variant="outlined"
                size="small"
                type="text"
                value={values.policy_number}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel textAlign={"center"}>
                Language Preferred
              </CustomFormLabel>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={2}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>English</Typography>
                  <Checkbox
                    checked={values.language_preferred === "EN"}
                    onChange={() => {
                      setFieldValue("language_preferred", "EN");
                    }}
                  />
                </Stack>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>French</Typography>
                  <Checkbox
                    checked={values.language_preferred === "FN"}
                    onChange={() => {
                      setFieldValue("language_preferred", "FN");
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <LabelCard heading="Insured Details" />

          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Customer Name</CustomFormLabel>
              <CustomTextField
                name="applicant_details.name"
                value={values.applicant_details.name}
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={5}>
              <CustomFormLabel>Address</CustomFormLabel>
              <LocationAutoComplete
                id="applicant_details.address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.address}
                onLocationChange={handleAddress}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Address 2</CustomFormLabel>
              <CustomTextField
                name="applicant_details.address_two"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.address_two}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Country</CustomFormLabel>
              <CustomTextField
                name="applicant_details.country"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.country}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>City</CustomFormLabel>
              <CustomTextField
                name="applicant_details.city"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>State</CustomFormLabel>
              <CustomTextField
                name="applicant_details.province_or_state"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.province_or_state}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Postal Code</CustomFormLabel>
              <CustomTextField
                name="applicant_details.postalcode"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.postalcode}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Contact Number</CustomFormLabel>
              <CustomTextField
                name="applicant_details.contact_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.contact_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Home</CustomFormLabel>
              <CustomTextField
                name="applicant_details.home"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.home}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Cell</CustomFormLabel>
              <CustomTextField
                name="applicant_details.cell"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.cell}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Business</CustomFormLabel>
              <CustomTextField
                name="applicant_details.business"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.business}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Fax</CustomFormLabel>
              <CustomTextField
                name="applicant_details.fax"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.fax}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Email</CustomFormLabel>
              <CustomTextField
                name="applicant_details.email"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Website</CustomFormLabel>
              <CustomTextField
                name="applicant_details.website"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.applicant_details.website}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Broker" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Broker Agent</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent"
                value={values.broker.broker_agent}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Address</CustomFormLabel>

              <LocationAutoComplete
                id="broker.broker_agent_address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_address}
                onLocationChange={handleBrokerAddress}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Unit/Suite</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent_unit_or_suite"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_unit_or_suite}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>City</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent_city"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_city}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Province/State</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent_state"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_state}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Country</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent_country"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_country}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Postal/ZIP Code</CustomFormLabel>
              <CustomTextField
                name="broker.broker_agent_zip"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_agent_zip}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Contact Number</CustomFormLabel>
              <CustomTextField
                name="broker.broker_contact_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_contact_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Home</CustomFormLabel>
              <CustomTextField
                name="broker.broker_home"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_home}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Business</CustomFormLabel>
              <CustomTextField
                name="broker.broker_business"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_business}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Cell</CustomFormLabel>
              <CustomTextField
                name="broker.broker_cell"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_cell}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Fax</CustomFormLabel>
              <CustomTextField
                name="broker.broker_fax"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_fax}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Broker Conract Number</CustomFormLabel>
              <CustomTextField
                name="broker.broker_contract_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_contract_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Broker Sub-Contract Number</CustomFormLabel>
              <CustomTextField
                name="broker.broker_sub_contract_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker.broker_sub_contract_number}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Policy Peroid" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Effective Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.policy_period_effective_date || ""}
                onChange={(newValue) => {
                  setFieldValue("policy_period_effective_date", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Effective Time</CustomFormLabel>
              <CustomTimePicker
                fullWidth
                value={values.policy_period_effective_time}
                onChange={(newValue) => {
                  setFieldValue(
                    "policy_period_effective_time",
                    newValue.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Expiry Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.policy_period_expiry_date || ""}
                onChange={(newValue) => {
                  setFieldValue("policy_period_expiry_date", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Expiry Time</CustomFormLabel>
              <CustomTimePicker
                fullWidth
                value={values.policy_period_expiry_time}
                onChange={(newValue) => {
                  setFieldValue("policy_period_expiry_time", newValue);
                }}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Name / Address Change" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <CustomFormLabel>
                Name Changed (Refer to Section 1) GIVE REASON FOR CHANGE{" "}
              </CustomFormLabel>
              <CustomTextField
                name="name_changed"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.name_changed}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <CustomFormLabel>
                Address Changed (Refer to Section 1){" "}
              </CustomFormLabel>
              <CustomTextField
                name="address_changed"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.address_changed}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="Risk Address" />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2.4}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.risk_address_checkbox === "Add"}
                  onChange={(e) => {
                    setFieldValue(
                      "risk_address_checkbox",
                      e.target.checked ? "Add" : ""
                    );
                  }}
                />
                <Typography>Add</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.risk_address_checkbox === "Delete"}
                  onChange={(e) => {
                    setFieldValue(
                      "risk_address_checkbox",
                      e.target.checked ? "Delete" : ""
                    );
                  }}
                />
                <Typography>Delete</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.risk_address_checkbox === "Change"}
                  onChange={(e) => {
                    setFieldValue(
                      "risk_address_checkbox",
                      e.target.checked ? "Change" : ""
                    );
                  }}
                />
                <Typography>Change</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} mt={1}>
              <DataTable {...describedRiskAddress} />
            </Grid>
          </Grid>

          <LabelCard heading="Loss Payee" />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2.4}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.loss_payee_checkbox === "Add"}
                  onChange={(e) => {
                    setFieldValue(
                      "loss_payee_checkbox",
                      e.target.checked ? "Add" : ""
                    );
                  }}
                />
                <Typography>Add</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.loss_payee_checkbox === "Delete"}
                  onChange={(e) => {
                    setFieldValue(
                      "loss_payee_checkbox",
                      e.target.checked ? "Delete" : ""
                    );
                  }}
                />
                <Typography>Delete</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={values.loss_payee_checkbox === "Change"}
                  onChange={(e) => {
                    setFieldValue(
                      "loss_payee_checkbox",
                      e.target.checked ? "Change" : ""
                    );
                  }}
                />
                <Typography>Change</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} mt={1}>
              <DataTable {...describedLossPayee} />
            </Grid>
          </Grid>

          <LabelCard heading=" Rating Information" />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Year Built</CustomFormLabel>
              <CustomTextField
                name="year_built"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.year_built}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>No. Of Storeys</CustomFormLabel>
              <CustomTextField
                name="of_storeys"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.of_storeys}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>No. Of Families</CustomFormLabel>
              <CustomTextField
                name="of_families"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.of_families}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>No. Of Units</CustomFormLabel>
              <CustomTextField
                name="of_units"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.of_units}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Ground Floor Area</CustomFormLabel>
              <Stack direction="row" spacing={1}>
                <CustomTextField
                  name="groung_floor_area"
                  variant="outlined"
                  size="small"
                  type="text"
                  sx={{
                    width: "80%",
                  }}
                  // fullWidth
                  value={values.groung_floor_area}
                  onChange={handleChange}
                />
                {/* <Stack direction="row" spacing={0} alignItems="center">
                  <Checkbox
                    checked={values.sqft_sqm === "sqft"}
                    onChange={(e) => {
                      setFieldValue("sqft_sqm", e.target.checked ? "sqft" : "");
                    }}
                  />
                  <Typography>Sq. ft</Typography>
                </Stack>
                <Stack direction="row" spacing={0} alignItems="center">
                  <Checkbox
                    checked={values.sqft_sqm === "sqm"}
                    onChange={(e) => {
                      setFieldValue("sqft_sqm", e.target.checked ? "sqm" : "");
                    }}
                  />
                  
                  <Typography>Sq. M.</Typography>
                </Stack> */}
                <ControlledCustomSelect
                  fullWidth
                  value={values.sqft_sqm}
                  name="sqft_sqm"
                  onChange={handleChange}
                  placeholder="Select"
                  displayEmpty
                  options={["Sq. ft.", "Sq. M."].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Stack>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Occupancy Type</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="occupancy"
                fullWidth
                displayEmpty
                value={values.occupancy || []}
                multiple
                onChange={handleChange}
                placeholder="Select"
                options={[
                  "Primary",
                  "Secondary",
                  "Seasonal",
                  "Rental",
                  "Rented to Third Party",
                  "Vacant",
                  "Unoccupied",
                  "Under Construction",
                ].map((occup) => {
                  return { label: occup, value: occup };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Structure Type</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="structure_type"
                fullWidth
                displayEmpty
                value={values.structure_type || []}
                onChange={handleChange}
                multiple
                placeholder="Select"
                options={[
                  "Detached",
                  "Semi-Detached",
                  "Townhouse",
                  "Rowhouse",
                  "HighRise",
                  "Mobile Home",
                  "Duplex",
                  "MultiPlex",
                  "Pre-Fab",
                ].map((structure) => {
                  return { label: structure, value: structure };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Construction</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="construction"
                fullWidth
                displayEmpty
                value={values.construction || []}
                multiple
                onChange={handleChange}
                placeholder="Select"
                options={[
                  "Brick",
                  "Cement",
                  "Frame",
                  "Aliminum",
                  "Masonry",
                  "Stone",
                  "Stucco",
                  "Fire Resistive",
                  "Steel",
                  "Masonry Veneer",
                  "Brick Veneer",
                  "Non-Fire Resistive Apt",
                ].map((construct) => {
                  return { label: construct, value: construct };
                })}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="body1" fontSize={"1.2rem"} fontWeight={600}>
            OutBuilding
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>No. Of</CustomFormLabel>
              <CustomTextField
                name="outbuilding.no_of"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.outbuilding.no_of}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Use</CustomFormLabel>
              <CustomTextField
                name="outbuilding.use"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.outbuilding.use}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Const.</CustomFormLabel>
              <CustomTextField
                name="outbuilding.const"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.outbuilding.const}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Heat</CustomFormLabel>
              <CustomTextField
                name="outbuilding.heat"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.outbuilding.heat}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Value</CustomFormLabel>
              <CustomTextField
                name="outbuilding.value"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.outbuilding.value}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="body1" fontSize={"1.2rem"} fontWeight={600}>
            Fire Protection
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Distance to Hydrant</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="fire_protection.distance_to_hydrant"
                fullWidth
                displayEmpty
                value={values.fire_protection.distance_to_hydrant}
                onChange={handleChange}
                placeholder="Select"
                options={distance_to_hydrant.map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Hydrant Type</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="fire_protection.hydrant_type"
                fullWidth
                displayEmpty
                value={values.fire_protection.hydrant_type}
                onChange={handleChange}
                placeholder="Select"
                options={hydrant_type.map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>
                Distance to Responding Fire Hall
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="fire_protection.distance_to_responding_fire_hall"
                fullWidth
                displayEmpty
                value={values.fire_protection.distance_to_responding_fire_hall}
                onChange={handleChange}
                placeholder="Select"
                options={distance_to_reponding_fire_hall.map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Fire Hall Name</CustomFormLabel>
              <CustomTextField
                name="fire_protection.fire_hall_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.fire_protection.fire_hall_name}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="body1" fontSize={"1.2rem"} fontWeight={600}>
            Oil Tank
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Year</CustomFormLabel>
              <CustomTextField
                name="oil_tank.year"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.oil_tank.year}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Type</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.oil_tank.type}
                name="oil_tank.type"
                onChange={handleChange}
                placeholder="Select"
                displayEmpty
                options={[
                  "Oil Tank Location Inside",
                  "Oil Tank Location Outside",
                  "In Ground",
                  "Above Ground",
                ].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="body1" fontSize={"1.2rem"} fontWeight={600}>
            Security System
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Fire</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="security_system.fire"
                fullWidth
                displayEmpty
                value={values.security_system.fire}
                onChange={handleChange}
                placeholder="Select"
                options={fire_burglar_smoke.map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Burglar</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="security_system.burglary"
                fullWidth
                displayEmpty
                value={values.security_system.burglary}
                onChange={handleChange}
                placeholder="Select"
                options={fire_burglar_smoke.map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Security Attendant</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="security_system.security_attendant"
                fullWidth
                displayEmpty
                value={values.security_system.security_attendant}
                onChange={handleChange}
                placeholder="Select"
                options={fire_burglar_smoke.map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Sprinkler</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="security_system.sprinkler"
                fullWidth
                displayEmpty
                value={values.security_system.sprinkler}
                onChange={handleChange}
                placeholder="Select"
                options={fire_burglar_smoke.map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Smoke Detectors</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="security_system.smoke_detectors"
                fullWidth
                displayEmpty
                value={values.security_system.smoke_detectors}
                onChange={handleChange}
                placeholder="Select"
                options={fire_burglar_smoke.map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Smoke Detector Type</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="security_system.smoke_detector_type"
                fullWidth
                displayEmpty
                value={values.security_system.smoke_detector_type}
                onChange={handleChange}
                placeholder="Select"
                options={smoke_detector_type.map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>No. of Detectors</CustomFormLabel>
              <CustomTextField
                name="security_system.no_of_detectors"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.security_system.no_of_detectors}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>
                If Any of the Above are Monitored, Monitored By
              </CustomFormLabel>
              <CustomTextField
                name="security_system.if_any_of_the_above_are_monitored_monitored_by"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.security_system
                    .if_any_of_the_above_are_monitored_monitored_by
                }
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Alarm Certificate Attached</CustomFormLabel>
              <Checkbox
                checked={values.security_system.alarm_certificate === "Yes"}
                value={values.security_system.alarm_certificate}
                onChange={(e, checked) => {
                  setFieldValue(
                    "security_system.alarm_certificate",
                    checked ? "Yes" : "No"
                  );
                }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="body1" fontSize={"1.2rem"} fontWeight={600}>
            Renovation Upgrade
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "unset",
                    },
                  }}
                >
                  <TableHead>
                    <TableCell>
                      {" "}
                      <Typography variant="h6" fontWeight={"600"}>
                        Renovation Upgrade
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Full
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Partial
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Year
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {values.renovation_upgrade.map((item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <Typography
                              sx={{
                                wordBreak: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {item.upgrade_name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="full"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.full}
                              onChange={handleRenovationUpgrade(index, "full")}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="partial"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.partial}
                              onChange={handleRenovationUpgrade(
                                index,
                                "partial"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="year"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.year}
                              onChange={handleRenovationUpgrade(index, "year")}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Amps</CustomFormLabel>
              <CustomTextField
                name="amps"
                size="small"
                type="number"
                fullWidth
                value={values.amps}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Amps Types</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.amps_options || []}
                name="amps_options"
                onChange={handleChange}
                placeholder="Select"
                displayEmpty
                multiple
                options={[
                  "Breakers",
                  "Fuses",
                  "Knob & Tube",
                  "Aluminum",
                  "Copper",
                ].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Copper</CustomFormLabel>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <CustomTextField
                  name="copper"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.copper}
                  onChange={handleChange}
                />
                <Typography
                  sx={{
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  %
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Plastic</CustomFormLabel>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <CustomTextField
                  name="plastic"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.plastic}
                  onChange={handleChange}
                />
                <Typography
                  sx={{
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  %
                </Typography>
                <CustomTextField
                  name="other"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.other}
                  onChange={handleChange}
                />
                <Typography
                  sx={{
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  %
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>Type</CustomFormLabel>
              <CustomTextField
                name="type"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.type}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="body1" fontSize={"1.2rem"} fontWeight={600}>
            Swimming Pool
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Year</CustomFormLabel>
              <CustomTextField
                name="swimming_pool.year"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.swimming_pool.year}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Pool Type</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="swimming_pool.pool_type"
                fullWidth
                displayEmpty
                value={values.swimming_pool.pool_type}
                onChange={handleChange}
                placeholder="Select"
                options={swimming_pool_type.map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="body1" fontSize={"1.2rem"} fontWeight={600}>
            Heating
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "unset",
                    },
                  }}
                >
                  <TableHead>
                    <TableCell>
                      {" "}
                      <Typography variant="h6" fontWeight={"600"}>
                        Heating
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Fuel
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Primary
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Auxiliary
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {values.heating_type.map((item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            {!item.isTypeOther ? (
                              <Typography
                                sx={{
                                  wordBreak: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {item.heating}
                              </Typography>
                            ) : (
                              <CustomTextField
                                name="heating"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                value={item.heating}
                                onChange={handleHeatingType(index, "heating")}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="fuel"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.fuel}
                              onChange={handleHeatingType(index, "fuel")}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="primary"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.primary}
                              onChange={handleHeatingType(index, "primary")}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="auxiliary"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.auxiliary}
                              onChange={handleHeatingType(index, "auxiliary")}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Divider sx={{ mb: 2 }} />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Solid Fuel Heating Unit</CustomFormLabel>
              <CustomTextField
                name="solid_heating_unit"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.solid_heating_unit}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>
                Heating Unit Professional Installation
              </CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.professional_installation}
                name="professional_installation"
                onChange={handleChange}
                placeholder="Select"
                displayEmpty
                options={["Yes", "No"].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>
                Heating Unit ULC, CSA, Or WH Approved
              </CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.heating_unit_ulc_csa_approved}
                name="heating_unit_ulc_csa_approved"
                onChange={handleChange}
                placeholder="Select"
                displayEmpty
                options={["Yes", "No"].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Heating Area SQ.M.</CustomFormLabel>
              <CustomTextField
                value={values.heating_area_sqm}
                name="heating_area_sqm"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Make</CustomFormLabel>
              <CustomTextField
                value={values.make}
                name="make"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Year</CustomFormLabel>
              <CustomTextField
                value={values.year}
                name="year"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>No. Face Cords Per Year</CustomFormLabel>
              <CustomTextField
                value={values.no_face_cords_per_year}
                name="no_face_cords_per_year"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="body1" fontSize={"1.2rem"} fontWeight={600}>
            Attachments
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "unset",
                    },
                  }}
                >
                  <TableHead>
                    <TableCell>
                      {" "}
                      <Typography variant="h6" fontWeight={"600"}>
                        Attachments
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Remarks
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Type
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Code
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {values.attachments_type.map((item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            {!item.isTypeOther ? (
                              <Typography
                                sx={{
                                  wordBreak: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {item.attachments}
                              </Typography>
                            ) : (
                              <CustomTextField
                                name="attachments"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                value={item.attachments}
                                onChange={handleChangeAttachments(
                                  "attachments",
                                  index
                                )}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="remarks"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.remarks}
                              onChange={handleChangeAttachments(
                                "remarks",
                                index
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <ControlledCustomSelect
                              fullWidth
                              value={item.yes_no}
                              name="yes_no"
                              onChange={handleSelectAttachments(
                                "primary",
                                index
                              )}
                              placeholder="Select"
                              displayEmpty
                              options={["Yes", "No"].map((template) => {
                                return { label: template, value: template };
                              })}
                            ></ControlledCustomSelect>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="code"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.code}
                              onChange={handleChangeAttachments("code", index)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <LabelCard heading="Coverage: Forms, Limit & Deductible" />

          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Package Form and Type</CustomFormLabel>
              <CustomTextField
                name="coverag_forms.package_form"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverag_forms.package_form}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Rating Plan</CustomFormLabel>
              <CustomTextField
                name="coverag_forms.rating_plan"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverag_forms.rating_plan}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Deductible</CustomFormLabel>
              <CurrencyTextField
                fullWidth
                showInputProps="dollar"
                value={values.coverag_forms.deductible}
                onChange={(newVal) => {
                  setFieldValue("coverag_forms.deductible", newVal);
                }}
              />
            </Grid>
            <Grid item xs={12} marginTop={1}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "unset",
                    },
                  }}
                >
                  <TableHead>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      {" "}
                      <Typography variant="h6" fontWeight={"600"}>
                        Single Limit
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Dwelling Building
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Detached Private Structure
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Personal Property
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Additional Living Expenses
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Legal Liability
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Voluntary Medical Payments
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Voluntary Property Damage
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {values.coverag_forms.coverag_form_table.map(
                      (item, index) => {
                        return (
                          <TableRow
                            key={item.key}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>
                              {" "}
                              <CurrencyTextField
                                fullWidth
                                value={item.single_limit}
                                onChange={handleCoverageFormCurrencyChange(
                                  index,
                                  "single_limit"
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              {" "}
                              <CurrencyTextField
                                fullWidth
                                value={item.dwelling_building}
                                onChange={handleCoverageFormCurrencyChange(
                                  index,
                                  "dwelling_building"
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              {" "}
                              <CurrencyTextField
                                fullWidth
                                value={item.detached_private}
                                onChange={handleCoverageFormCurrencyChange(
                                  index,
                                  "detached_private"
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              {" "}
                              <CurrencyTextField
                                fullWidth
                                value={item.personal_property}
                                onChange={handleCoverageFormCurrencyChange(
                                  index,
                                  "personal_property"
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              {" "}
                              <CurrencyTextField
                                fullWidth
                                value={item.additional_living}
                                onChange={handleCoverageFormCurrencyChange(
                                  index,
                                  "additional_living"
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              {" "}
                              <CurrencyTextField
                                fullWidth
                                value={item.legal_liability}
                                onChange={handleCoverageFormCurrencyChange(
                                  index,
                                  "legal_liability"
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              {" "}
                              <CurrencyTextField
                                fullWidth
                                value={item.voluntary_medical}
                                onChange={handleCoverageFormCurrencyChange(
                                  index,
                                  "voluntary_medical"
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              {" "}
                              <CurrencyTextField
                                fullWidth
                                value={item.voluntary_property}
                                onChange={handleCoverageFormCurrencyChange(
                                  index,
                                  "voluntary_property"
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <LabelCard heading="Additional Coverages " />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "unset",
                    },
                  }}
                >
                  <TableHead>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      {" "}
                      <Typography variant="h6" fontWeight={"600"}>
                        Acion
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Coverage Code
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "25%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Coverage Description
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Limit #1
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Deductible
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Deductible Type
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "12.5%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        1st Type of
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {values.additional_coverage.map((item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="action"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.action}
                              onChange={handleAdditionalCoveragesChange(
                                index,
                                "action"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="coverage_code"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.coverage_code}
                              onChange={handleAdditionalCoveragesChange(
                                index,
                                "coverage_code"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="coverage_description"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.coverage_description}
                              onChange={handleAdditionalCoveragesChange(
                                index,
                                "coverage_description"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="limit"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.limit}
                              onChange={handleAdditionalCoveragesChange(
                                index,
                                "limit"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="deductible"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.deductible}
                              onChange={handleAdditionalCoveragesChange(
                                index,
                                "deductible"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="deductible_type"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.deductible_type}
                              onChange={handleAdditionalCoveragesChange(
                                index,
                                "deductible_type"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="type_of"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.type_of}
                              onChange={handleAdditionalCoveragesChange(
                                index,
                                "type_of"
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <LabelCard heading="Liability Exposures" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Do you Own/Rent more than one location?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="liability_exposures.do_you_own_rent_more_than_one_location"
                fullWidth
                displayEmpty
                value={
                  values.liability_exposures
                    .do_you_own_rent_more_than_one_location
                }
                onChange={handleChange}
                placeholder="Select"
                options={["Yes", "No"].map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Do you Own any Saddle/Draft Animals?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="liability_exposures.no_of_saddle_draft_animals"
                fullWidth
                displayEmpty
                value={values.liability_exposures.no_of_saddle_draft_animals}
                onChange={handleChange}
                placeholder="Select"
                options={["Yes", "No"].map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                If Yes, Are any location rented to others?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="liability_exposures.if_yes_are_any_location_rented_to_others"
                fullWidth
                displayEmpty
                value={
                  values.liability_exposures
                    .if_yes_are_any_location_rented_to_others
                }
                onChange={handleChange}
                placeholder="Select"
                options={["Yes", "No"].map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Is there any kind of business operation?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="liability_exposures.is_there_any_kind_of_business_operation"
                fullWidth
                displayEmpty
                value={
                  values.liability_exposures
                    .is_there_any_kind_of_business_operation
                }
                onChange={handleChange}
                placeholder="Select"
                options={["Yes", "No"].map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Is there a co-occupant that requires coverages?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="liability_exposures.is_there_a_co_occupant_that_requires_coverage"
                fullWidth
                displayEmpty
                value={
                  values.liability_exposures
                    .is_there_a_co_occupant_that_requires_coverage
                }
                onChange={handleChange}
                placeholder="Select"
                options={["Yes", "No"].map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>If Yes, Describe Business</CustomFormLabel>
              <CustomTextField
                name="liability_exposures.if_yes_describe_business"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.liability_exposures.if_yes_describe_business}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>Co-Occupant Name</CustomFormLabel>
              <CustomTextField
                name="liability_exposures.co_occupant_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.liability_exposures.co_occupant_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>
                Is there any non family member working as staff?
              </CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="liability_exposures.is_there_any_non_family_member_working_as_a_staff"
                fullWidth
                displayEmpty
                value={
                  values.liability_exposures
                    .is_there_any_non_family_member_working_as_a_staff
                }
                onChange={handleChange}
                placeholder="Select"
                options={["Yes", "No"].map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>Do you rent rooms to others?</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="liability_exposures.do_you_rent_rooms_to_others"
                fullWidth
                displayEmpty
                value={values.liability_exposures.do_you_rent_rooms_to_others}
                onChange={handleChange}
                placeholder="Select"
                options={["Yes", "No"].map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>Number of Dogs in the Household</CustomFormLabel>
              <CustomTextField
                name="liability_exposures.number_of_dogs_in_the_household"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={
                  values.liability_exposures.number_of_dogs_in_the_household
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>Breed's of Dogs</CustomFormLabel>
              <CustomTextField
                name="liability_exposures.breed_of_dogs"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.liability_exposures.breed_of_dogs}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>Is there a daycare operation?</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="liability_exposures.do_you_rent_rooms_to_others"
                fullWidth
                displayEmpty
                value={values.liability_exposures.do_you_rent_rooms_to_others}
                onChange={handleChange}
                placeholder="Select"
                options={["Yes", "No"].map((business) => {
                  return { label: business, value: business };
                })}
              ></ControlledCustomSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>Other Exposures</CustomFormLabel>
              <CustomTextField
                name="liability_exposures.other_exposures"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.liability_exposures.other_exposures}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <LabelCard heading="Liability Extension From Primary Location" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "unset",
                    },
                  }}
                >
                  <TableHead>
                    <TableCell sx={{ width: "15%", textAlign: "center" }}>
                      {" "}
                      <Typography variant="h6" fontWeight={"600"}>
                        Action
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "15%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Code
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "35%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Liability Coverage Description
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "15%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Number of
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Type of
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {values.liability_extensions.map((item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="action"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.action}
                              onChange={handleLiabilityExtentionChange(
                                index,
                                "action"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="code"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.code}
                              onChange={handleLiabilityExtentionChange(
                                index,
                                "code"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="liability_coverage_discription"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.liability_coverage_discription}
                              onChange={handleLiabilityExtentionChange(
                                index,
                                "liability_coverage_discription"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="number_of"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.number_of}
                              onChange={handleLiabilityExtentionChange(
                                index,
                                "number_of"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Stack direction={"row"} spacing={1}>
                              <CustomTextField
                                name="type_of_one"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                value={item.type_of_one}
                                onChange={handleLiabilityExtentionChange(
                                  index,
                                  "type_of_one"
                                )}
                              />
                              <CustomTextField
                                name="type_of_two"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                value={item.type_of_two}
                                onChange={handleLiabilityExtentionChange(
                                  index,
                                  "type_of_two"
                                )}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <LabelCard heading="Discounts And Surcharges" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "unset",
                    },
                  }}
                >
                  <TableHead>
                    <TableCell sx={{ width: "15%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Code
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "35%" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Discount and Surcharges Description
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "15%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        %
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Premium Applied to Base
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {values.discount_and_surcharges.map((item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ width: "15%", textAlign: "center" }}>
                            <Typography variant="h6">{item.code}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6">
                              {item.discount_surcharge_discription}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="percentage"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.percentage}
                              onChange={handleDiscountSurchargeChange(
                                index,
                                "percentage"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Stack direction={"row"} spacing={1}>
                              <CustomTextField
                                name="premium_applied_base_one"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                value={item.premium_applied_base_one}
                                onChange={handleDiscountSurchargeChange(
                                  index,
                                  "premium_applied_base_one"
                                )}
                              />
                              <CustomTextField
                                name="premium_applied_base_two"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                value={item.premium_applied_base_two}
                                onChange={handleDiscountSurchargeChange(
                                  index,
                                  "premium_applied_base_two"
                                )}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <LabelCard heading="Schedule Personal Property Detail" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "unset",
                    },
                  }}
                >
                  <TableHead>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Action
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        #
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Class Code
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Description(Including Serial/Identification)
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Coverage Code
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Type of 1
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Type of 2
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Purchase/Appraisal Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Deductible
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        % Disc Applicable
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Amount of Insurance
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {values.schedule_personal.map((item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="action"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.action}
                              onChange={handleSchedulePersonalChange(
                                index,
                                "action"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6">{item.hash}</Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="class_code"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.class_code}
                              onChange={handleSchedulePersonalChange(
                                index,
                                "class_code"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="description"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.description}
                              onChange={handleSchedulePersonalChange(
                                index,
                                "description"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="coverage_code"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.coverage_code}
                              onChange={handleSchedulePersonalChange(
                                index,
                                "coverage_code"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="type_of_one"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.type_of_one}
                              onChange={handleSchedulePersonalChange(
                                index,
                                "type_of_one"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="type_of_two"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.type_of_two}
                              onChange={handleSchedulePersonalChange(
                                index,
                                "type_of_two"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Stack direction={"row"} spacing={1}>
                              <CustomTextField
                                name="purchase"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                value={item.purchase}
                                onChange={handleSchedulePersonalChange(
                                  index,
                                  "purchase"
                                )}
                              />
                              <CustomTextField
                                name="appraisal_date"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                value={item.appraisal_date}
                                onChange={handleSchedulePersonalChange(
                                  index,
                                  "appraisal_date"
                                )}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="deductible"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.deductible}
                              onChange={handleSchedulePersonalChange(
                                index,
                                "deductible"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="disc_applicable"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.disc_applicable}
                              onChange={handleSchedulePersonalChange(
                                index,
                                "disc_applicable"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <CustomTextField
                              name="amount_of_insurance"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.amount_of_insurance}
                              onChange={handleSchedulePersonalChange(
                                index,
                                "amount_of_insurance"
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <LabelCard heading="WaterCraft and Trailers" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={4}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Checkbox
                  checked={values.stand_alone_watercraft_policy === true}
                  value={values.stand_alone_watercraft_policy}
                  onChange={(e, checked) => {
                    setFieldValue(
                      "stand_alone_watercraft_policy",
                      checked ? true : false
                    );
                  }}
                />
                <Typography>Sewer Backup Questionaire Attached</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "unset",
                    },
                  }}
                >
                  <TableHead>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Action
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Year
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Description Make/Model
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Coverage
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Deductible
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Identification Serial Number
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {values.watercraft_and_trailer_one.map((item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="action"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.action}
                              onChange={handleWaterCraftTrailerOne(
                                index,
                                "action"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="year"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.year}
                              onChange={handleWaterCraftTrailerOne(
                                index,
                                "year"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="description_make_model"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.description_make_model}
                              onChange={handleWaterCraftTrailerOne(
                                index,
                                "description_make_model"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="coverage"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.coverage}
                              onChange={handleWaterCraftTrailerOne(
                                index,
                                "coverage"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="deductible"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.deductible}
                              onChange={handleWaterCraftTrailerOne(
                                index,
                                "deductible"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="identification_serial_number"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.identification_serial_number}
                              onChange={handleWaterCraftTrailerOne(
                                index,
                                "identification_serial_number"
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} lg={12}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    whiteSpace: {
                      xs: "nowrap",
                      sm: "unset",
                    },
                  }}
                >
                  <TableHead>
                    <TableCell sx={{ width: "30%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Action
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "30%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Type
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Style
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "30%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Construction
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "30%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        Length
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Horse Power
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Purchase Date
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "30%", textAlign: "center" }}>
                      <Typography variant="h6" fontWeight={"600"}>
                        New Or Used
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Purchase Price (including contents)
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Replacement Cost
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Value of Contents included in the purchase price
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Value of Non Standard Equipment
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={"600"}>
                        Maximum Speed
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {values.watercraft_and_trailer_two.map((item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="action"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.action}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "action"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="type"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.type}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "type"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="style"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.style}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "style"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="construction"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.construction}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "construction"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="length"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.length}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "length"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="horse_power"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.horse_power}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "horse_power"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="purchase_date"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.purchase_date}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "purchase_date"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="new_or_used"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.new_or_used}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "new_or_used"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="purchase_price"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.purchase_price}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "purchase_price"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="replacement_cost"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.replacement_cost}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "replacement_cost"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="value_contents_purchase_price"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.value_contents_purchase_price}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "value_contents_purchase_price"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="value_of_non_standard"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.value_of_non_standard}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "value_of_non_standard"
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <CustomTextField
                              name="maximum_speed"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={item.maximum_speed}
                              onChange={handleWaterCraftTrailerTwo(
                                index,
                                "maximum_speed"
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
