import React from "react";
import {
  ICommAutoDescribedSectionOne,
  ICommAutomobileApplication,
} from "../../../../../../redux/commAutoPolicy/commAutoApplication/commAutoApplication.types";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import produce from "immer";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { Stack } from "@mui/system";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { MODULE_IDS } from "../../../../../../constants/enums";

import { CSIO_DATA_LIST } from "../../../../../../constants/constants";
import {
  IAutomobileCSIOPolicyReqModelInfo,
  IAutomobileCSIOPolicyReqVehicleInfo,
  IAutomobileCSIOPolicyState,
} from "../../../../../../redux/automobileCSIOPolicyChangeReq/automobileCSIOPolicyChangeReq.types";
import moment from "moment";
import { StandadCard } from "../../../../../../components/Cards";

export const AutomobileCSIOPolicyChanngeReqStep2: React.FC<{
  values: IAutomobileCSIOPolicyState;
  handleChange: any;
  setValues: any;
  setFieldValue: any;
  changeTab: (value: number) => void;
}> = (props) => {
  const { values, setValues, handleChange, setFieldValue } = props;

  const updateVehicleModelInfo = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(values.vehicle_model_info, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("vehicle_model_info", newValues);
  };

  const handleChangeVehicleModelInfo =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateVehicleModelInfo(key, rowIndex, e.target.value);
    };

  const handleSelectVehicleModelInfo =
    (key: string, rowIndex: number) => (e: SelectChangeEvent<unknown>) => {
      updateVehicleModelInfo(key, rowIndex, e.target.value);
    };

  const describedVehicleModelInfo: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 5,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "model_cc",
        headerName: "Model or C.C.",
        fieldName: "model_cc",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOPolicyReqModelInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.model_cc}
              onChange={handleChangeVehicleModelInfo(
                "model_cc",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "no_of_cyls",
        headerName: "No. of CYLS.",
        fieldName: "no_of_cyls",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOPolicyReqModelInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.no_of_cyls}
              onChange={handleChangeVehicleModelInfo(
                "no_of_cyls",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "truck_gross_weight",
        headerName: "Truck Gross Vehicle Weight",
        fieldName: "truck_gross_weight",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOPolicyReqModelInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.truck_gross_weight}
              onChange={handleChangeVehicleModelInfo(
                "truck_gross_weight",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "purchase_price",
        headerName: "Purchase Price Include Equipment",
        fieldName: "purchase_price",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOPolicyReqModelInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.purchase_price}
              onChange={handleChangeVehicleModelInfo(
                "purchase_price",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "bodyType",
        headerName: "Purchased By Insured",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOPolicyReqModelInfo) => {
          return (
            <Stack direction={"row"} spacing={2}>
              <CustomDatePicker
                fullWidth
                value={
                  row.purchased_by_insured_date
                    ? moment(row.purchased_by_insured_date).format("YYYY-MM")
                    : ""
                }
                onChange={(event) => {
                  const newValue = moment(event.target.value).format("YYYY-MM");
                  updateVehicleModelInfo(
                    "purchased_by_insured_date",
                    value.column.rowIndex,
                    newValue
                  );
                }}
              />
              <ControlledCustomSelect
                fullWidth
                value={row.purchased_by_insured}
                name="template"
                onChange={handleSelectVehicleModelInfo(
                  "purchased_by_insured",
                  value.column.rowIndex
                )}
                placeholder="Type"
                displayEmpty
                options={["NEW", "USED", "DEMO"].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Stack>
          );
        },
      },
    ],
    items: values.vehicle_model_info,
  };

  const updateVehicleInformation = (
    key: string,
    rowIndex: number,
    value: any
  ) => {
    const newValues = produce(values.vehicle_information, (draftValues) => {
      //@ts-ignore
      draftValues[rowIndex][key] = value;
    });
    setFieldValue("vehicle_information", newValues);
  };

  const handleChangeVehicleInformation =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateVehicleInformation(key, rowIndex, e.target.value);
    };

  const describedVehicleInformation: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 5,
    uniqueRowKeyName: "key",
    columns: [
      {
        key: "vehNo",
        headerName: "Veh No.",
        fieldName: "vehNo",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOPolicyReqVehicleInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.vehNo}
              onChange={handleChangeVehicleInformation(
                "vehNo",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "year",
        headerName: "Year",
        fieldName: "year",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOPolicyReqVehicleInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.year}
              onChange={handleChangeVehicleInformation(
                "year",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "trade_name",
        headerName: "Trade Name / Make",
        fieldName: "trade_name",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOPolicyReqVehicleInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.trade_name}
              onChange={handleChangeVehicleInformation(
                "trade_name",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "body_type",
        headerName: "Body Type",
        fieldName: "body_type",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOPolicyReqVehicleInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.body_type}
              onChange={handleChangeVehicleInformation(
                "body_type",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "vin_serial_number",
        headerName: "V.I.N. (Serial Number)",
        fieldName: "vin_serial_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileCSIOPolicyReqVehicleInfo) => {
          return (
            <CustomTextField
              fullWidth
              value={row.vin_serial_number}
              onChange={handleChangeVehicleInformation(
                "vin_serial_number",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.vehicle_information,
  };

  return (
    <StandadCard>
      <Typography variant="h3" sx={{ mt: 3 }} fontWeight={600}>
        Vehicle
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.vehicle_checkbox === "Add"}
              onChange={(e) => {
                setFieldValue("vehicle_checkbox", "Add");
              }}
            />
            <Typography>Add</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.vehicle_checkbox === "Delete"}
              onChange={(e) => {
                setFieldValue("vehicle_checkbox", "Delete");
              }}
            />
            <Typography>Delete</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.vehicle_checkbox === "Change"}
              onChange={(e) => {
                setFieldValue("vehicle_checkbox", "Change");
              }}
            />
            <Typography>Change</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={values.vehicle_checkbox === "Substitute"}
              onChange={(e) => {
                setFieldValue("vehicle_checkbox", "Substitute");
              }}
            />
            <Typography>
              Substitute
              {values.vehicle_checkbox === "Substitute" && ":  Previous V.I.N."}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2.8}>
          {values.vehicle_checkbox === "Substitute" && (
            <CustomTextField
              name="previous_vin_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.previous_vin_number}
              onChange={handleChange}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <DataTable {...describedVehicleInformation} />
        </Grid>

        <Grid item xs={12}>
          <DataTable {...describedVehicleModelInfo} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>USE</CustomFormLabel>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={values.pleasure_business === "Pleasure"}
                onChange={(e) => {
                  setFieldValue("pleasure_business", "Pleasure");
                }}
              />
              <Typography>Pleasure</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={values.pleasure_business === "Business"}
                onChange={(e) => {
                  setFieldValue("pleasure_business", "Business");
                }}
              />
              <Typography>Business</Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Is Vehicle Used To Commute</CustomFormLabel>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={values.vehicle_used_commute === "YES"}
                onChange={(e) => {
                  setFieldValue("vehicle_used_commute", "YES");
                }}
              />
              <Typography>YES</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={values.vehicle_used_commute === "NO"}
                onChange={(e) => {
                  setFieldValue("vehicle_used_commute", "NO");
                }}
              />
              <Typography>NO</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <CustomTextField
                name="km_one_way"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.km_one_way}
                onChange={handleChange}
              />
              <Typography>Km One Way</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Annual Distance</CustomFormLabel>
          <Stack direction="row" alignItems="center" spacing={1}>
            <CustomTextField
              name="annual_distance"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.annual_distance}
              onChange={handleChange}
            />
            <Typography>Km</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={2.6}>
          <CustomFormLabel>Fuel</CustomFormLabel>
          <CustomTextField
            name="fuel"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.fuel}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container spacing={2} mt={2} alignItems={"center"}>
        <Grid item xs={12} lg={3.9}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={values.coverage_data.bi_pd_checkbox === "Yes"}
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.bi_pd_checkbox",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>B.I/P.D</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.bi_pd_limit"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.bi_pd_limit}
                onChange={handleChange}
              />
              <Typography>Limit</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} lg={4}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={values.coverage_data.all_perils_checkbox === "Yes"}
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.all_perils_checkbox",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>All Perils</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.all_perils_deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.all_perils_deductible}
                onChange={handleChange}
              />
              <Typography>Deductible</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} lg={4}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={
                  values.coverage_data.income_replacement_limit === "Yes"
                }
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.income_replacement_limit",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>Income Replacement</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.income_replacement_checkbox"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.income_replacement_checkbox}
                onChange={handleChange}
              />
              <Typography>Limit</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container spacing={2} mt={2} alignItems={"center"}>
        <Grid item xs={12} lg={3.9}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={
                  values.coverage_data.accidents_benfits_checkbox === "Yes"
                }
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.accidents_benfits_checkbox",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>Accidents Benefits</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.bi_pd_limit"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.bi_pd_limit}
                onChange={handleChange}
              />
              <Typography>Limit</Typography> */}
            </Stack>
          </Stack>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} lg={4}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={values.coverage_data.collision_checkbox === "Yes"}
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.collision_checkbox",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>Collision</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.collision_deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.collision_deductible}
                onChange={handleChange}
              />
              <Typography>Deductible</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} lg={4}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={
                  values.coverage_data.caregiver_dependant_checkbox === "Yes"
                }
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.caregiver_dependant_checkbox",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>Caregiver & Dependant Care</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.caregiver_dependant_deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.caregiver_dependant_deductible}
                onChange={handleChange}
              />
              <Typography>Limit</Typography> */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container spacing={2} mt={2} alignItems={"center"}>
        <Grid item xs={12} lg={3.9}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={
                  values.coverage_data.uninsured_automobile_checkbox === "Yes"
                }
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.uninsured_automobile_checkbox",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>Uninsured Automobile / U.M</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.bi_pd_limit"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.bi_pd_limit}
                onChange={handleChange}
              />
              <Typography>Limit</Typography> */}
            </Stack>
          </Stack>
        </Grid>

        <Divider orientation="vertical" flexItem />

        <Grid item xs={12} lg={4}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={values.coverage_data.comprehensive_checkbox === "Yes"}
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.comprehensive_checkbox",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>Comprehensive</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.comprehensive_deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.comprehensive_deductible}
                onChange={handleChange}
              />
              <Typography>Deductible</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} lg={4}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={values.coverage_data.medical_rehab_checkbox === "Yes"}
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.medical_rehab_checkbox",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>Caregiver & Dependant Care</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.caregiver_dependant_deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.caregiver_dependant_deductible}
                onChange={handleChange}
              />
              <Typography>Limit</Typography> */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container spacing={2} mt={2} alignItems={"center"}>
        <Grid item xs={12} lg={3.9}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={values.coverage_data.dcpd_checkbox === "Yes"}
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.dcpd_checkbox",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>D.C./P.D. (where applicable)</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.dcpd_deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.dcpd_deductible}
                onChange={handleChange}
              />
              <Typography>Deductible</Typography>
            </Stack>
          </Stack>
        </Grid>

        <Divider orientation="vertical" flexItem />

        <Grid item xs={12} lg={4}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={
                  values.coverage_data.specific_perils_checkbox === "Yes"
                }
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.specific_perils_checkbox",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>Specified Perils</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.specific_perils_deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.specific_perils_deductible}
                onChange={handleChange}
              />
              <Typography>Deductible</Typography>
            </Stack>
          </Stack>
        </Grid>

        <Divider orientation="vertical" flexItem />

        <Grid item xs={12} lg={4}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                checked={
                  values.coverage_data.death_funeral_benefits_checkbox === "Yes"
                }
                onChange={(e) => {
                  setFieldValue(
                    "coverage_data.death_funeral_benefits_checkbox",
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
              <Typography>
                Death and Funeral And/Or Indexation Benefit
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <Typography>$</Typography>
              <CustomTextField
                name="coverage_data.caregiver_dependant_deductible"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.coverage_data.caregiver_dependant_deductible}
                onChange={handleChange}
              />
              <Typography>Limit</Typography> */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container spacing={2} mt={2} alignItems={"center"}>
        <Grid item xs={12} lg={3}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Checkbox
              checked={values.coverage_data.endorsements_checkbox === "Yes"}
              onChange={(e) => {
                setFieldValue(
                  "coverage_data.endorsements_checkbox",
                  e.target.checked ? "Yes" : "No"
                );
              }}
            />
            <Typography>Endorsements (S.E.F, Q.E.F, O.P.C.F.)</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={9}>
          <CustomTextField
            name="coverage_data.endorsements_text"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.coverage_data.endorsements_text}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Stack direction={"row"} spacing={2} marginTop={3}>
        <RoleBasedCustomButton
          moduleId={MODULE_IDS.AUTOMOBILE}
          variant="outlined"
          onClick={() => props.changeTab(0)}
        >
          Prev
        </RoleBasedCustomButton>
        <RoleBasedCustomButton
          moduleId={MODULE_IDS.AUTOMOBILE}
          variant="contained"
          onClick={() => props.changeTab(2)}
        >
          Next
        </RoleBasedCustomButton>
      </Stack>
    </StandadCard>
  );
};
